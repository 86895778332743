@use '../styles/_variables' as *

.card
	position: relative
	z-index: 0
	border: 1px solid $border-color
	border-radius: $border-radius
	background: $color-background
	transition: 200ms border
	width: 100%
	display: flex
	flex-direction: column
	--card-vertical-padding: 20px
	--card-horizontal-padding: 20px

	&.view-clickable,
	a &

		&:not(:hover)
			border-color: $color-primary

		&:hover,
		&:focus-within
			border-color: $color-primary
			color: $color-primary


	&.view-default
		//

	&.view-small
		--card-vertical-padding: 15px

	&-heroImage
		//

	&-in
		padding: var(--card-vertical-padding) var(--card-horizontal-padding)
		flex-grow: 1
