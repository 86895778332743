@use '../styles/_variables' as *

.filter
	$root: &
	$transition-duration: 0.2s

	&-opener
		border: 1px solid $border-color
		border-radius: $border-radius
		background-color: $color-background
		padding: 11px 15px

	&-opener
		text-align: left
		min-width: 150px

		#{$root}.is-open &
			color: $color-primary

		#{$root}.is-open &,
		#{$root}.is-active &
			border-color: $color-primary

	&-label
		margin: 0
		color: #000000
		font-weight: $font-weight-heavy
		font-size: 13px
		font-family: $font-base

		#{$root}.is-open &
			color: inherit

	&-expand
		margin-top: 4px
		font-size: 15px
		font-weight: $font-weight-book
		display: flex
		flex-direction: column
		white-space: nowrap
		color: $color-text !important

		&-label
			#{$root}-preview ~ &
				height: 0
				overflow: hidden
				opacity: 0
				visibility: hidden

	&-expand-label,
	&-preview
		&::after
			content: ''
			border-bottom: 1px solid
			border-right: 1px solid
			width: 6px
			height: 6px
			margin-left: 5px
			display: inline-block
			transform-origin: 100% 100%
			transform: translateX(-50%) translateY(-1px) rotate(45deg)

	&-popupClose
		color: $color-primary
		padding: 0
		margin-top: 5px
		border: none
		background-color: transparent
		text-decoration: underline

		&:hover,
		&:focus
			text-decoration: none

