@use '../styles/_variables' as *

.languagePicker
	$root: &

	position: relative
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column
	padding-bottom: 21px
	margin-top: -4px

	&-current
		font-size: 30px
		display: flex

	&-options
		position: absolute
		left: -10px
		right: -10px
		top: 29px
		font-size: 15px
		text-transform: uppercase
		padding-bottom: 3px
		border-radius: $border-radius
		background-color: $color-background
		border: 1px solid transparent

		#{$root}.is-open &,
		&:focus-within
			border-color: #BFBFBF
			box-shadow: 0 0 5px rgba(#000000, 0.1)

	&-option
		&:not(:first-child)
			display: none

			#{$root}.is-open &
				display: block

	&-opener,
	&-link
		display: block
		padding: 2px 5px
		width: 100%
		line-height: 1

	&-opener
		font-weight: $font-weight-black
		border: none
		background-color: transparent
		text-transform: inherit
		padding-top: 5px
		outline: none

		&::after
			content: ''
			border-top: 5px solid
			border-left: 3px solid transparent
			border-right: 3px solid transparent
			display: inline-block
			margin-left: 5px
			position: relative
			top: -2px
			transform-origin: 50% 40%

			#{$root}.is-open &
				transform: scaleY(-1)

	&-link
		color: inherit
		text-decoration: none
		text-align: center
		padding-right: 16px

		&:hover,
		&:focus
			text-decoration: underline
