@use '../styles/_variables' as *
@use "sass:math"

.articleList
	$gap-horizontal: 40px
	$gap-vertical: 50px

	overflow: hidden

	&-items
		display: flex
		flex-wrap: wrap
		margin: math.div(-$gap-vertical,2) math.div(-$gap-horizontal,2)
		padding: 40px 0 35px

		&::after
			content: ''

	&-items::after,
	&-item
		flex-basis: 50%
		flex-grow: 1
		min-width: 300px

	&-item
		padding: math.div($gap-vertical, 2) math.div($gap-horizontal, 2)

	&-more
		text-align: center

		&-link
			display: inline-block
