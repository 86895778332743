@use '../styles/_variables' as *

.fencePostCapsConfiguratorLayout
	$root: &

	&-perex 
		padding-bottom: 50px
		&:is(#{$root}-export #{$root}-perex)
			padding-bottom: 0
			

	&-export-option
		display: none
		max-width: 500px
		padding-bottom: 2rem

		&:is(#{$root}-export #{$root}-export-option)
			display: block

	&-inquiry
		&:is(#{$root}-export #{$root}-inquiry)
			display: none

	.cardBox-content
		margin: 0
		display: grid 
		grid-template-columns: 45px auto 
		gap: 15px
		align-items: center

		&>*:first-child
			border-radius: 4px

		h4, p
			margin: 0
			font-family: $font-base

		h4 
			font-size: 1rem
			vertical-align: baseline
			padding-bottom: 5px


	&-inner 
		&:is(#{$root}-export #{$root}-inner)
			display: none

		&-selection
			display: grid 
			gap: 60px
			align-items: center
			grid-template-columns: 5fr 2fr
			@media screen and (max-width: $content-width)
				grid-template-columns: 4fr 3fr

			@media screen and (max-width: $content-width-narrow)
				grid-template-columns: 1fr

				&>*:first-child
					grid-row: 2

			svg 
				width: 100%
				max-width: 350px

		&-options 
			display: grid 
			gap: 20px
			grid-template-columns: 1fr 1fr
			@media screen and (max-width: $content-width)
				grid-template-columns: 1fr

			

		&-inputs
			display: grid 
			gap: 20px
			grid-template-columns: 2fr 2fr 1fr
			align-items: center

			@media screen and (max-width: $content-width)
				grid-template-columns: 1fr
				row-gap: 0

	&-final 
		.grid--price
			margin-bottom: 40px
			padding-bottom: 20px 
			border-bottom: dotted 3px $color-primary

			table 
				th, td
					padding: 10px 15px
					@media screen and (max-width: $content-width-narrow)
							padding: 2px 5px
							font-size: 0.9em

					text-align: right

					&:first-child
						padding-left: 0
						text-align: left

						@media screen and (min-width: $content-width-narrow)
							padding-right: 180px

				td:last-child 
					padding-left: 2rem
					
			@media screen and (max-width: $content-width-narrow)
				font-size: 1rem

				table 
					td:last-child 
						padding-left: 0.5rem
						padding-bottom: 1rem

					td:first-child
						padding-right: 2rem
						padding-bottom: 1rem

					tr:last-child td 
						padding-bottom: 0

		.grid--share
			display: flex 
			gap: 20px

			&>*:first-child
				flex: 1

			@media screen and (max-width: $content-width-narrow)
				flex-direction: column

			&:is(#{$root}-export .grid--share)
				display: none


	.circle 
		font-size: 1rem
		line-height: 36px 
		width: 30px
		height: 30px 
		display: inline-block 
		background: $color-primary
		color: $color-text-inverted
		border-radius: 19px
		z-index: 10
		text-align: center
		margin-right: 10px
		vertical-align: middle
		font-family: $font-head

		@media screen and (max-width: $content-width-small)
			display: block 
			margin-bottom: 8px

	.text-primary
		color: $color-primary-alternative

	.input-pending
		font-weight: 700
		outline-color: rgba($color-primary, 0.6)
		color: $color-primary-alternative
		-webkit-text-fill-color: $color-primary-alternative

	