.spacer
	$breakpoints: 320, 500, 800, 1000, 1200, 1400
	$unit: 1px

	--spacer-break-initial: 0
	--spacer-height: (var(--spacer-break-initial), 0)
	padding: 1px 0
	margin-top: calc(var(--spacer-height) * #{$unit} - 2px)

	@each $breakpoint in $breakpoints
		@media (min-width: ($breakpoint * 1px))
			--spacer-height: var(--spacer-break-#{$breakpoint})
