@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.cross
	width: var(--cross-width)
	height: var(--cross-width)

	position: relative
	display: inline-block

	transform: rotate(45deg)

	cursor: pointer

	&::before,
	&::after
		+position.absolute
		content: ''
		background-color: var(--cross-color)

	&::before
		width: var(--cross-thickness)
		height: 100%
		left: 50%
		transform: translateX(-50%)

	&::after
		height: var(--cross-thickness)
		width: 100%
		top: 50%
		left: 0
		transform: translateY(-50%)

	&.view-default
		--cross-width: 14px
		--cross-thickness: 2px
		--cross-color: #{$color-text}

	&.view-popup
		--cross-width: 22px
		--cross-thickness: 3px
		--cross-color: #{$color-text-inverted}

	&.view-removeButton
		--cross-width: 22px
		--cross-thickness: 3px
		--cross-color: #D50000
