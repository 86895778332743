@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.header
	$root: &
	$transition-duration: 0.2s

	position: relative
	color: #000000
	background-color: #FFFFFF

	&-in
		padding: 14px $page-horizontal-spacing

	&-content
		display: flex
		align-items: center
		max-width: $content-width-wide
		margin: 0 auto

	&-logo
		font-size: 2rem
		margin: 0 40px 0 0

		#{$root}.is-mobileSubMenuOpen &
			display: none

		@media (min-width: 350px)
			font-size: 2.4rem

		@media (min-width: $break-header)
			font-size: 2rem
			margin-right: 20px

		&-link
			color: inherit
			display: flex

	&-primary,
	&-secondary
		display: none
		list-style-type: none
		margin: 0
		padding: 0
		flex-wrap: wrap
		align-items: center 

		@media (min-width: $break-header)
			display: flex

	&-primary
		flex-grow: 1
		white-space: nowrap
		font-weight: $font-weight-black
		font-size: 15px

		&-item
			position: relative

			&:not(:last-child)::after
				+position.absolute(-5px 0 -5px null)
				content: ''
				border-right: 1px solid #CECECE

		&-link
			padding: 0 19px
			color: inherit
			text-decoration: none

			&:hover,
			&:focus
				text-decoration: underline

			&.is-active
				color: $color-primary

			&.view-hasSubMenu::after
				content: ''
				display: inline-block
				border-left: 4px solid transparent
				border-right: 4px solid transparent
				border-top: 6px solid
				margin-left: 0.5em
				position: relative
				top: -1px
				transition-property: transform
				transition-duration: $transition-duration
				transform-origin: 0 45%

			&.is-open.view-hasSubMenu::after
				transform: scaleY(-1)

	&-secondary
		position: relative
		z-index: 1

		&-item
			margin-left: 25px

		&-link
			color: inherit
			font-weight: $font-weight-book
			font-size: 15px
			text-decoration: none

			&:hover,
			&:focus
				text-decoration: underline

			&.is-active
				color: $color-primary

		@media (max-width: 1500px)
			justify-content: flex-end

	&-hamburger
		flex-grow: 1
		display: flex
		justify-content: flex-end

	&-mobileLanguagePicker
		margin-right: 20px
		position: relative
		z-index: 1
		margin-bottom: -5px
		margin-top: -5px

	&-mobileSearch
		margin-right: 20px
		position: relative
		z-index: 1
		margin-bottom: -5px
		margin-top: -5px

	&-mobileLanguagePicker,
	&-mobileShoppingList,
	&-mobileSearch
		display: none

		#{$root}.is-mobileSubMenuOpen &
			display: block

	&-hamburger,
	&-mobileShoppingList,
	&-mobileLanguagePicker,
	&-mobileSearch
		@media (min-width: $break-header)
			display: none

	&-subMenuList
		border-top: 1px solid #DDDDDD

		&-in
			display: none

			@media (min-width: $break-header)
				display: block

	&-subMenuMobile
		position: absolute
		top: 100%
		left: 0
		right: 0
		pointer-events: none

		@media (min-width: $break-header)
			display: none
