@use '../../styles/_variables' as *

.navSearch
	$color-border: #D6D6D6
	$border-radius: 3px

	$root: &
	position: relative
	margin-left: 20px
	cursor: pointer

	&-overlay 
		position: fixed
		top: 0
		left: 0
		width: 100vw
		height: 75px
		background: rgba(255,255,255, 0.9)
		z-index: 20

		display: flex
		justify-content: center
		padding-top: 15px 

	&-bar 
		// position: absolute
		// right: 45px
		// bottom: 0px
		width: 660px

		@media (max-width: 700px)
			width: 85vw	

	&-hits 
		width: 660px
		background-color: $color-background
		border-left: 1px solid $color-border
		border-right: 1px solid $color-border
		border-bottom: 1px solid $color-border
		border-bottom-left-radius: $border-radius
		border-bottom-right-radius: $border-radius
		z-index: 20

		@media (max-width: 700px)
			width: 85vw


	&-icon 
		display: flex
		justify-content: center
		align-items: center
		margin-bottom: 3px

	&-options 
		padding-top: 3px
		font-size: 13px
		font-weight: 800

	&-wrapper
		&:hover 
			text-decoration: underline
