@use '../styles/_variables' as *
@use 'swiper/swiper.scss'
@use 'sass:math'

.swiper
	position: relative
	z-index: 0

	&-container
		padding: 30px 0

	&-slide
		width: auto
		height: auto

	&-button
		$height: 110px
		$arrow-offset: -2px

		&-prev,
		&-next
			position: absolute
			z-index: 2
			left: 0
			top: calc(50% - #{math.div($height, 2)})
			width: 34px
			height: $height
			background-color: $color-primary
			color: $color-text-inverted
			display: flex
			justify-content: center
			align-items: center
			cursor: pointer
			transition-property: opacity, visibility
			transition-duration: 0.2s

			&::before
				$height: 11px

				content: ''
				border-bottom: 2px solid
				width: 11px
				height: $height
				margin-top: math.div(-$height, 2)
				display: inline-block

		&-disabled
			opacity: 0
			visibility: hidden

		&-prev
			border-top-right-radius: $border-radius
			border-bottom-right-radius: $border-radius

			&::before
				border-left: 2px solid
				transform-origin: 0 100%
				transform: translateX(#{$arrow-offset}) rotate(45deg)

		&-next
			left: auto
			right: 0
			border-top-left-radius: $border-radius
			border-bottom-left-radius: $border-radius

			&::before
				border-right: 2px solid
				transform-origin: 100% 100%
				transform: translateX(#{-$arrow-offset}) rotate(-45deg)

	&-pagination
		position: absolute
		bottom: 3px
		left: 0
		right: 0
		display: flex
		justify-content: flex-end
		max-width: $content-width + 2 * $page-horizontal-spacing
		padding: 0 $page-horizontal-spacing
		margin: 0 auto

		&-bullet
			position: relative
			width: 8px
			height: 8px
			background-color: #e0e0e0
			border-radius: 50%
			margin-left: 7px
			cursor: pointer

			@media screen and (max-width: $content-width-narrow)
				width: 13px
				height: 13px
				margin-right: 7px

			&::before
				content: ''
				position: absolute
				top: -3px
				left: -3px
				right: -3px
				bottom: -3px

			&-active
				background-color: $color-primary
