@use '../styles/_variables' as *

.videoTiles
	display: grid 
	grid-template-columns: 1fr
	gap: 20px

	@container (inline-size > #{$content-width-small})
		grid-template-columns: 1fr 1fr


	@container (inline-size > #{$content-width-narrow})
		grid-template-columns: 1fr 1fr 1fr


	&-item 
		&-footer
			position: absolute
			right: 1rem
			bottom: 0.5rem
			color: $color-text-light
