@use '../styles/_variables' as *

.vendorLocationSearchBox
	display: flex

	& > *:first-child
		flex-grow: 1

	&-find
		padding: 0 10px
		color: $color-primary
		border: none
		background-color: transparent
