@use '../styles/_variables' as *

.topProducts
	&-item
		padding: 0 20px
		min-height: 100%
		display: flex

		&-in
			position: relative
			max-width: 380px
			width: 90vw
