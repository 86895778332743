@use '../styles/_variables' as *

.subMenuDetail
	$root: &

	background-color: #F4F4F4
	padding: 40px $page-horizontal-spacing 30px
	color: $color-text

	&-in
		max-width: $content-width
		margin: 0 auto
		display: flex

	&-title
		font-weight: $font-weight-black
		font-size: inherit
		font-family: $font-base
		padding-bottom: 16px
		border-bottom: 1px solid #AFAFAF
		margin-bottom: 13px

	&-column
		font-size: 15px
		display: flex
		flex-direction: column

		&.view-primaryNavigation
			flex-grow: 100
			max-width: 200px

		&.view-boxedNavigation
			max-width: 250px

		&.view-imageNavigation
			flex-grow: 1

			&:not(:first-child):last-child
				width: 460px

		&:first-child:last-child
			width: 100%

		&:not(:last-child)
			margin-right: 50px

		&.view-contained #{$root}-title
			border-bottom: none
			margin-bottom: 0

	&-list
		margin: 0
		padding: 0
		list-style-type: none
		min-width: 170px
		flex-grow: 1

		&-link
			color: inherit
			text-decoration: none
			line-height: 1.2
			padding: 4px 0
			display: inline-block

			&:hover,
			&:focus
				text-decoration: underline

		&-item
			&.view-highlighted
				&:not(:first-child)
					margin-top: 20px

				&:not(:last-child)
					margin-bottom: 20px

		&-item.view-highlighted &-link
			font-weight: $font-weight-black
			text-decoration: underline
			color: inherit
			display: inline-block

			&:hover,
			&:focus
					text-decoration: none

		#{$root}-column.view-contained &
			border: 1px solid #CFCFCF
			border-radius: $border-radius
			background-color: $color-background
			overflow: auto
			padding: 9px 15px
			flex-basis: 300px

	&-images
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
		gap: 23px 20px
		padding-top: 4px

		&-item
			position: relative

			&::before
				content: ''
				display: block
				padding-top: calc(100% / (221 / 158))
