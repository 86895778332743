@use '../styles/_variables' as *

.subMenuMobile
	$root: &

	background-color: $color-background
	min-height: 80vh
	transition-property: opacity, visibility
	transition-duration: 0.2s
	opacity: 0
	visibility: hidden
	display: flex
	flex-direction: column
	pointer-events: auto

	&.is-open
		opacity: 1
		visibility: inherit

	&-main,
	&-footer
		position: relative
		z-index: 0
		margin: 0
		list-style-type: none

	&-main
		padding: 30px $page-horizontal-spacing
		flex-grow: 1

	&-mainItem
		padding: 5px 0

		&.is-open
			color: $color-primary

		&-link
			display: inline-flex
			align-items: center
			padding: 10px 0
			font-size: 25px
			font-weight: $font-weight-black
			color: inherit
			text-decoration: none

			&:hover,
			&:focus
				text-decoration: underline

		&.has-subItems &-link
			&::after
				content: ''
				border-left: 0.2em solid transparent
				border-right: 0.2em solid transparent
				border-top: 0.35em solid
				margin-left: 0.5em
				margin-bottom: -0.1em
				transform-origin: 50% 35%

		&.has-subItems.is-open &-link
			&::after
				transform: scaleY(-1)

	&-footer
		position: sticky
		bottom: 0
		display: flex
		justify-content: flex-start
		overflow: auto
		padding: 0
		border-top: 1px solid #979797
		background-color: $color-background

	&-footerItem

		&-link
			display: inline-block
			padding: 20px $page-horizontal-spacing
			color: inherit
			text-decoration: none
			white-space: nowrap

			&:hover,
			&:focus
				text-decoration: underline

	&-subItems
		display: none
		padding: 5px 0
		margin: 0
		list-style-type: none
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr))
		gap: 0 20px
		color: $color-text

		#{$root}-mainItem.is-open &
				display: grid

	&-subItem
		&-link
			display: inline-block
			color: inherit
			font-weight: $font-weight-book
			font-size: 15px
			padding: 5px 0

			&:hover,
			&:focus
				text-decoration: underline
