@use '../styles/_variables' as *

.errorNotice
	padding: 50px $page-horizontal-spacing
	text-align: center
	flex-grow: 1
	display: flex
	flex-direction: column
	min-height: 100%

	&::before,
	&::after
		content: ''
		flex-grow: 1

	&::after
		flex-grow: 2

	&-in
		max-width: $content-width-narrow
		margin: 0 auto

	&-title
		text-transform: uppercase
		font-size: 49px
		margin: 0

	&-content
		margin-top: 20px
		line-height: 1.4
