@use '../styles/_variables' as *

.tabs
	max-width: ($content-width + 2 * $page-horizontal-spacing)
	margin: 0 auto
	padding: 0 $page-horizontal-spacing

	.tab
		max-width: 200px

	&.view-popup
		padding: 0
		max-width: none
