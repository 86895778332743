@use '../styles/_variables' as *

.linkBoxes
	text-align: left

	&-title
		margin-bottom: 40px

	&-items
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(260px, 1fr))
		gap: 30px
