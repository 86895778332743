@use '../styles/_variables' as *

.checkbox
	$transition-duration: 0.2s
	$root: &

	position: relative
	display: inline-flex
	justify-content: flex-start
	align-items: center

	&-control
		position: absolute
		opacity: 0
		pointer-event: none


	&-indicator
		width: 18px
		height: 18px
		border: 1px solid
		border-radius: 2px
		position: relative
		transition: color $transition-duration
		margin: 1px 15px 1px 0
		flex-shrink: 0

		#{$root}.view-bland &
			width: 18px 
			height: 18px

			@media screen and (max-width: $content-width-narrow)
				width: 22px 
				height: 22px

		&::before
			content: ''
			position: absolute
			bottom: 25%
			right: 27%
			width: 6px
			height: 10px
			border-bottom: 2px solid
			border-right: 2px solid
			transform: rotate(45deg)
			opacity: 0
			transition: opacity $transition-duration

	&:hover &-control:not(:disabled) ~ &-indicator
		box-shadow: 0 0 5px rgba(#000000, 0.2)

	&-label
		font-size: 15px
		font-weight: $font-weight-book
		align-self: center
		line-height: 18px
		vertical-align: bottom
		margin-top: 6px

		#{$root}.view-bland &
			margin-top: 2px
			line-height: 13px
			font-size: 12px
			color: #AAAAAA

	&-control:checked ~ &-indicator, &-control ~ &-indicator--checked
		color: $color-primary

		&::before
			opacity: 1

	&-control:focus-visible ~ &-indicator
		outline: 2px solid $color-text

	&-control:disabled ~ &
		&-indicator,
		&-label
			color: $color-text-light
