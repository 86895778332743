@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.subMenuDesktop
	$gap-vertical: 30px
	$gap-horizontal: 16px
	$size-arrow: 18px
	$root: &

	position: relative
	z-index: 0
	--subMenuDesktop-arrow-offset: #{-$size-arrow}

	&-band
		position: relative
		background-color: $color-background-inverted
		color: $color-text-inverted
		padding: calc($gap-vertical / 4) $page-horizontal-spacing
		overflow: visible

		&::before
			+position.absolute(null null 100% (-$size-arrow))
			left: calc(var(--subMenuDesktop-arrow-offset))
			content: ''
			color: $color-background-inverted
			margin-left: -$size-arrow
			width: 2 * $size-arrow
			border-left: $size-arrow solid transparent
			border-right: $size-arrow solid transparent
			border-bottom: $size-arrow solid

		&-in
			max-width: $content-width-wide
			margin: 0 auto
			display: flex
			flex-wrap: wrap

		&-item
			color: inherit
			font-size: 15px
			font-weight: $font-weight-book
			padding: calc($gap-vertical / 4) calc($gap-horizontal / 2)
			white-space: nowrap
			display: flex
			flex-direction: column

			&::after
				content: attr(data-content)
				height: 0
				overflow: hidden
				visibility: hidden

			&::after,
			&.is-active
				font-weight: $font-weight-heavy
				text-decoration: none

			&:hover
				text-decoration: none

	&-detail
		position: absolute
		top: 100%
		left: 0
		right: 0
		opacity: 0
		visibility: hidden
		transition-property: opacity, visibility
		transition-duration: 0.2s
		box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.2)

		#{$root}.is-detailOpen &
			opacity: 1
			visibility: inherit
