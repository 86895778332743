@use '../styles/_variables' as *
@use "sass:math"

.productLayout
	--productLayout-box-horizontal-padding: 20px

	padding-left: $page-horizontal-spacing
	padding-right: $page-horizontal-spacing
	text-align: left

	@media screen and (max-width: $content-width-small)
		margin-bottom: 1rem

	&-in
		max-width: $content-width
		margin: 0 auto
		display: grid
		column-gap: 32px
		row-gap: 32px
		align-items: start

		@media (min-width: 1000px)
			grid-template-columns: 480px 1fr

	&-main
		display: flex
		gap: 32px
		flex-direction: column
		height: 100%
		justify-content: flex-end

		&-productTypes
			em 
				color: $color-text-inverted-bland
				font-style: normal
				font-weight: $font-weight-heavy
				font-size: 12px

			ul
				display: flex 
				list-style: none 
				margin: 0
				padding: 5px 0 14px 0

				@media screen and (max-width: $content-width-narrow)
					flex-wrap: wrap
				 
				li
					display: inline-block
					white-space: nowrap
					
					a 
						font-size: 15px
						text-decoration: none

						&:hover
							color: $color-primary-alternative

					&:not(:last-child)
						&::after
							content: "/"
							color: $color-text-inverted-bland
							display: inline-block
							margin-left: 7px 
							margin-right: 7px

	&-header

		@media screen and (max-width: $content-width-narrow)
			display: flex 
			flex-direction: column

		h1 
			display: flex
			flex-direction: column
			margin: 0
			font-size: 49px
			line-height: 1.1

		&-name
			font-family: $font-head
			font-weight: $font-weight-normal
			text-transform: uppercase
			padding-right: 1rem
			vertical-align: top
			display: inline-block

		&-sub
			font-size: 20px
			font-family: $font-base
			vertical-align: top
			display: inline-block	

			&::first-letter
				text-transform: uppercase

			&:not(:last-child)
				margin-right: 0.5em

			&-empty
				margin: 0
				max-height: 0	

		&-complementaryDimensions
			font-family: $font-base
			font-size: 20px
			word-wrap: break-word
			white-space: normal
			vertical-align: top
			display: inline-block
			margin-top: 0.1rem
			line-height: 1.1

			&::first-letter
				text-transform: uppercase

		&-attributes
			float: right

			@media screen and (max-width: $content-width-narrow)
				float: none
				order: 5
				margin-top: 10px

				.attributes-item
					&:first-child 
						margin-left: 0


	&-description,
	&-expand
		font-size: 19px
		font-weight: $font-weight-book
		line-height: 27px

	&-expand
		display: inline

		&-button
			text-decoration: none
			color: $color-primary
			border: none
			background-color: transparent
			padding: 0
			font-weight: inherit

			&:hover,
			&:focus
				text-decoration: underline

	&-complementaryDimensions
		margin-top: 1.5em
		font-size: 15px
		font-weight: $font-weight-book
		line-height: normal

	&-box
		overflow: hidden
		box-shadow: 0 2px 6px rgba(#000000, 0.1)
		border-radius: $border-radius
		border: 1px solid #E1E1E1
		background-color: $color-background

	&-aside
		display: flex
		height: 100%
		border: 1px solid #DFDFDF
		border-radius: $border-radius
		background-color: $color-background

		&-in
			position: relative
			display: flex
			justify-content: center 
			width: 100%

			.gallery
				max-width: 500px
				width: 100%
		
		@media screen and (max-width: 1000px)
			&::after 
				padding-top: calc(75%)

			.gallery-stage
				overflow: hidden

		@media screen and (max-width: $content-width-small)
			&::after 
				padding-top: calc(100% + 100px)

			.gallery-stage
				overflow: hidden

		grid-row: 2
		@media (min-width: 1000px)
			grid-row: 1 / 3

	&-action
		padding: 20px var(--productLayout-box-horizontal-padding)


	&-label 
		font-size: 12px
		margin-top: 0
		margin-bottom: 5px
		min-height: 1.2em

		&-title 
			font-size: 14px
			margin-bottom: 3px
			//color: $color-primary-alternative

	&-variants
		padding: 20px var(--productLayout-box-horizontal-padding)

	&-options
		padding: 20px var(--productLayout-box-horizontal-padding)

		&>div 
			display: flex
			justify-content: space-between
			gap: 20px

			.quantityInput
				//background: $color-background-contained
				border-radius: $border-radius
				padding: 5px
				margin-bottom: 10px

			.productLayout-button-wrap
				min-width: 50%
				

				.button
					width: 100%

				@media screen and (max-width: $content-width)
					min-width: unset

					.button-in
						padding: 7px 7px

			@media screen and (max-width: $content-width-narrow)
				flex-wrap: wrap

				.productLayout-button-wrap 
					width: 100%

				

	&-pills

		&>div
			display: flex 
			gap: 20px		
			flex-wrap: wrap


		@media (max-width: 1000px)
			grid-row: 3

		@media screen and (max-width: $content-width-narrow)
			&>div 
				gap: 10px
				padding-bottom: 1rem

			.button-in 
				font-size: 13px 
				padding: 0px 5px
			
	&-parameters
		padding: 20px var(--productLayout-box-horizontal-padding)
		overflow: hidden
		box-shadow: 0 2px 6px rgba(#000000, 0.1)
		border-radius: $border-radius
		border: 1px solid #E1E1E1
		background-color: $color-background-contained




.productRacks-cta
	color: $color-primary
	font-weight: $font-weight-heavy
	margin-top: 0.5rem
	display: inline-block