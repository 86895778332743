@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.cookie-consent
	
	position: fixed 
	left: 0
	bottom: 0px
	z-index: 1500
	opacity: 0
	transition: opacity 400ms
	pointer-events: none
	max-height: 90vh

	&--active
		display: block
		opacity: 1
		pointer-events: all

	&--inner 
		padding: 40px 20px
		box-shadow: 0 8px 12px rgba(0, 0, 0, 0.23)
		border-top: solid $color-primary-alternative 10px
		background: $color-background
		width: 600px
		overflow-y: auto
		max-height: 90vh

		h6
			font-size: 1.2rem
			margin-top: 1.5rem
			margin-bottom: 0rem

		h5
			font-size: 1.5rem
			margin-top: 0
			margin-bottom: 1rem

		.checkbox-label
			margin-left: 1rem
			color: $color-text !important

		&--flex 
			margin-top: 2rem
			display: flex 
			justify-content: space-between
			align-items: center

		a 
			text-decoration: underline
			cursor: pointer

		p 
			line-height: 1.3
			

		@media screen and (max-width: $content-width-narrow)
			width: unset 
			padding: 40px 15px