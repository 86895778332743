@use '_variables' as *

*,
*::before,
*::after
	box-sizing: border-box

:root
	color-scheme: light

body
	color: $color-text
	background-color: $color-background
	font-family: $font-base
	overflow-y: scroll

button
	cursor: pointer

a
	color: $color-primary

a,
button
	&:focus:not(:focus-visible)
		outline: none

label
	cursor: pointer

h1, h2, h3, h4, h5, h6
	font-family: $font-head
	font-weight: 400

h1 
	font-size: 49px

h2
	font-size: 1.8rem

h3
	font-size: 1.6rem

h4
	font-size: 1.3rem

h5
	font-size: 1.1rem

h6
	font-size: 1rem

.sr-only
		@media screen 
			display: none
			

@media print 
	@page 
		size: A4 landscape
		margin: 0

	html, body 
		width: 297mm // A4 Paper width
		height: 100%
		font-size: 11pt
	