@use '../styles/_variables' as *
@use "sass:math"

.mainContacts
	$gap-horizontal: 20px
	$gap-vertical: 30px

	display: flex
	flex-wrap: wrap
	margin: math.div(-$gap-vertical, 2) math.div(-$gap-horizontal, 2)

	&-item
		flex-basis: 250px
		flex-shrink: 0
		flex-grow: 1
		text-align: center

		&-in
			padding: math.div($gap-vertical, 2) math.div($gap-horizontal, 2)
			max-width: 340px
			margin: 0 auto
