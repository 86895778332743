@use '../styles/_variables' as *

.articleTile
	position: relative
	display: flex 
	flex-direction: column
	height: 500px 
	justify-content: flex-end

	&-image
		height: 400px
		width: 100%
		position: absolute 
		top: 0
		right: 9px
		background-color: #F8F8F8
		border-radius: $border-radius
		overflow: hidden


	&-card,
	&-in::after
		width: 100%
		flex-shrink: 0

	&-in
		display: flex
		align-items: flex-end

		&::after
			content: ''
			padding-top: calc(100% / (588 / 300))
			margin-left: -100%
			visibility: hidden

	&-card
		padding-left: 20px

		&-in
			margin-left: auto
			max-width: 370px
