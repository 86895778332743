@use '../styles/_variables' as *

.videoList
	&-item

		& + &
			margin-top: 20px

	&--grid
		display: grid
		gap: 5px
		grid-template-columns: 1fr 1fr

		@media screen and (max-width: $content-width-wide)
			display: block