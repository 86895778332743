@use '../styles/_variables' as *

.quantityHeader
	white-space: normal

	&-label
		text-align: left
		&+div
			position: fixed 
			bottom: 20px

			.quantityHeader-popup
				display: block
				width: 300px
				padding: 10px
				background: $color-background

				&-label
					margin-bottom: 6px
					text-align: left
					font-weight: regular

				&-chevron
					margin-left: 1.2em

			@media screen and (max-width: $content-width)
				right: 20px

			
			@media screen and (max-width: $content-width-small)
				right: 0px
				width: 100vw
				left: 0px
				bottom: unset
				top: 70px

				.quantityHeader-popup
					padding-left: 25px
					padding-right: 25px
