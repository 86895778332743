@use '../styles/_variables' as *

.helpSubtitle
	padding: 0 $page-horizontal-spacing

	&-in
		max-width: $content-width
		margin: 40px auto
		display: flex
		justify-content: space-between
		align-items: center
		flex-wrap: wrap

	&-title
		margin: 0 1em 0 0
		font-family: $font-base
		font-weight: $font-weight-heavy
		font-size: 25px

	&-askQuestion
		display: flex
		align-items: center
		font-size: 15px
		font-weight: $font-weight-book
		border: none
		padding: 0
		background-color: transparent
		color: $color-primary

		&:hover,
		&:focus
			text-decoration: underline

		&-icon
			flex-shrink: 0
			margin-right: 0.5em

		&-text
			//
