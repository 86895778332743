@use '../styles/_variables' as *

.hidingHeader
	position: relative
	--hidingHeader-height: auto
	--hidingHeader-bounds-height: auto
	--hidingHeader-animation-offset: 0px
	z-index: 3
	height: var(--hidingHeader-bounds-height)
	margin-bottom: calc(var(--hidingHeader-height) - var(--hidingHeader-bounds-height))
	pointer-events: none

	&-in
		position: relative
		position: sticky
		top: 0
		pointer-events: auto
		transition: transform 0.2s
		transform: translateY(var(--hidingHeader-animation-offset))

	@media print
		display: none
