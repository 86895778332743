@use '../styles/_variables' as *
@use "sass:math"

.galleryMasonry
	$gap: 10px

	margin: math.div(-$gap, 2)

	&-item
		display: flex
		padding: math.div($gap, 2)
		width: 50%

		@media (min-width: 700px)
			width: math.div(100%, 3)

		@media (min-width: 1000px)
			width: 25%

		&-in
			position: relative
			border-radius: $border-radius
			overflow: hidden
			border: none
			padding: 0
			width: 100%

.ril-toolbar
	@media screen and (max-width: $content-width-narrow)
		background-color: #000000ba !important

.ril-toolbar__item
	background-color: #000000ba !important
	padding: 10px

	@media screen and (max-width: $content-width-narrow)
		padding: 0


.ril-caption
	overflow: visible	

	div 
		color: initial

	a:is(.ril-caption-content>a)
		color: $color-text-inverted
		text-decoration: none 
		cursor: pointer
		transition: color 150ms

		&:hover 
			color: $color-primary

.ril-toolbar 
	height: 80px

	.ril__toolbarSide
		height: 80px

	.ril-toolbar__item
		padding-top: 10px
		line-height: 25px
		white-space: normal

.ril__navButtonPrev, .ril__navButtonNext
	background-color: #000000ba