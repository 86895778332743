@use '../styles/_variables' as *

.cardDownload
	position: relative

	&--loading 
		overflow-x: hidden
		border-radius: 4px

		&::before 
			content: ""
			height: 8px 
			position: absolute
			bottom: 0
			left: 0
			width: 100% 
			background-color: $color-primary
			z-index: 2

		&::after 
			@keyframes cardDownload-progress
				0%
					transform: translateX(-200%)
				50%
					transform: translateX(0%) scaleX(0.5)
				100%
					transform: translateX(200%)

			content: ""
			height: 7px 
			position: absolute
			bottom: 1px
			left: 0	
			width: 50% 
			z-index: 5
			background-color: $color-primary-alternative
			animation: cardDownload-progress 0.75s linear infinite
			

	.cardPicture

		.cardPicture-link-text
			display: none

		.cardPicture-content
			justify-content: center
			height: 4.8em

		.cardPicture-image
			display: flex 
			justify-content: center
			flex-direction: column

			width: 50px
			height: 60px

		.cardPicture-title
			margin: 0
			max-height: 4.8em
			overflow: hidden

		.cardDownload-icon
			position: relative
			width: 50px
			height: 60px

			display: flex 
			justify-content: center
			flex-direction: column

			img
				object-position: center center

		

