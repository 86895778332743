@use '../styles/sass-base/mixins/position'
@use '../styles/_variables' as *

.tab
	$tab-active-bgcolor: #f2f2f2
	$tab-inactive-bgcolor: #cbcbcb
	$tab-hover-bqcolor: #dedcdc

	position: relative
	height: 40px
	color: #797979
	font-size: 14px
	font-weight: 800
	border: 1px solid $tab-inactive-bgcolor
	border-bottom: none
	background-color: $tab-inactive-bgcolor
	border-radius: $border-radius $border-radius 0 0
	transition: background-color 200ms, border-color 200ms
	pointer-events: auto
	padding: 0 10px
	display: inline-flex
	justify-content: center
	align-items: center
	text-decoration: none

	@media (min-width: 500px)
		font-size: 15px
		padding-left: 30px
		padding-right: 30px

	&:not(:last-child)
		margin-right: 7px

	&:hover,
	&:focus
		outline: none
		background-color: $tab-hover-bqcolor
		border-color: $tab-hover-bqcolor

	&.view-default,
	&.view-contained
		&.is-active
			color: $color-primary
			background-color: $tab-active-bgcolor
			border-color: #c7c7c7
			transition: none

			&::after
				+position.absolute(null null -1px 0)
				content: ''
				width: 100%
				height: 1px
				background-color: $tab-active-bgcolor

	&.view-contained
		&.is-active
			&,
			&::after
				background-color: $color-background-contained

	&.view-popup
		border: none
		background-color: transparent
		color: $color-text-inverted
		overflow: hidden

		&::before
			content: ''
			+position.absolute(0)
			background-color: $color-background
			opacity: .25

		&.is-active
			color: $color-primary
			background-color: $color-background
			border-color: transparent
			transition: none

			&::before
				display: none

@media print 
	.tab:not(.is-active)
		display: none 
