@use '../styles/_variables' as *

.representativesGrid
	display: grid
	grid-template-columns: 1fr
	gap: 20px 25px
	text-align: center

	@media (min-width: 500px)
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))

	&-item
		line-height: 22px
		font-size: 15px

	&-thumbnail
		position: relative
		background-color: rgba(#000000, 0.05)
		border-radius: $border-radius
		overflow: hidden
		transition: opacity $gallery-hover-transition-duration

		&:hover,
		&:focus
			opacity: $gallery-hover-opacity

		&::before
			content: ''
			display: block
			padding-top: 77%

		&-in
			position: absolute
			top: 0
			left: 0
			bottom: 0
			display: block
			width: 100%
			height: 100%
			border: none
			background-color: transparent

	&-title
		margin: 15px 0 0
		font-size: inherit
		font-weight: $font-weight-heavy
		font-family: $font-base

	&-note
		margin-top: 6px
