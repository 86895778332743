@use '../styles/_variables' as *

.react-tooltip
	$color-background: rgba(#000000, 0.68)

	background-color: $color-background
	color: $color-text-inverted
	font-weight: 300
	font-size: 12px
	border-radius: 8px
	text-align: center

	&-arrow
		border-color: $color-background

.ril__toolbar
	background-color: transparent

