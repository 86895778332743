@use '../styles/_variables' as *

.cardBoxGrid
	display: grid
	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr))
	gap: 15px
	margin: 26px 0 66px

	&-item
		//
