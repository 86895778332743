@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'
@use 'sass:math'

.hamburgerButton
	$thickness-line: 5px
	$root: &

	border: none
	background: none
	padding: 0
	text-align: center
	text-transform: uppercase
	font-weight: 900
	font-size: 12px
	color: $color-primary

	&-lines
		position: relative
		margin: 0 auto 6px
		width: 37px
		height: (3 + 2) * $thickness-line

	&-line
		+position.absolute(calc(50% - #{math.div($thickness-line, 2)}) 0 null)
		height: $thickness-line
		background-color: currentColor
		transition-property: opacity, transform
		transition-duration: 0.2s

		&:nth-child(1)
			transform: translateY(#{-$thickness-line * 2})

			#{$root}.is-open &
				transform: rotate(45deg)

		&:nth-child(2)
			#{$root}.is-open &
				opacity: 0

		&:nth-child(3)
			transform: translateY(#{$thickness-line * 2})

			#{$root}.is-open &
				transform: rotate(-45deg)
