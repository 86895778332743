@use '../styles/_variables' as *

@keyframes quantityInput-pulse
	100%
		border-color: transparent

.quantityInput
	$transition-duration: 0.2s
	$width-limit: 70px

	display: flex
	justify-content: center
	font-size: 12px

	&-units,
	&-squareMeters
		display: flex
		align-items: center

		&:not(:empty)
			min-width: $width-limit

	&-units
		justify-content: flex-end

	&-squareMeters
		margin-left: 0.5em

	&-computed
		color: rgba($color-text, 0.5)
		line-height: 1

	&-input
		max-width: $width-limit
		position: relative

		&::before
			content: ''
			border: 2px solid rgba($color-primary, 0.6)
			border-radius: $border-radius
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			margin: -1px
			pointer-events: none
			opacity: 0
			transition-property: opacity
			transition-duration: $transition-duration * 2

		&.is-highlight::before
			opacity: 1
			animation: quantityInput-pulse 0.6s alternate infinite ease-out
