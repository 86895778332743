@use '../styles/_variables' as *

.companyDetail
	font-size: 15px
	font-weight: $font-weight-book
	line-height: 1.3

	&-title
		font-weight: $font-weight-heavy
		font-family: inherit
		margin: 0 0 4px
		font-size: inherit
