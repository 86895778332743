@use '../styles/_variables' as *

.removeFromShoppingListButton
	display: flex
	border: none
	padding: 5px
	background-color: transparent
	opacity: 0.3
	transition: opacity 0.2s

	&:hover,
	&:focus
		opacity: 1
