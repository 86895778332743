@use '../styles/_variables' as *

.searchResultsTitle
	font-family: $font-base
	font-weight: $font-weight-heavy
	font-size: 20px
	margin: 0 0 1em

	&-query
		font-weight: $font-weight-black
