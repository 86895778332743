@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'
@use 'sass:math'

.footnote
	$break: 800px

	color: $color-text-inverted-bland
	padding-top: 60px

	@media (min-width: $break)
		display: flex
		justify-content: space-between

	&-links,
	&-attribution
		&::before
			+position.absolute(0 (-$page-horizontal-spacing) null)
			content: ''
			border-top: 1px solid $color-footer-divider

			@media (min-width: $content-width + 2 * $page-horizontal-spacing)
				left: 0
				right: 0

	&-links
		$spacing-vertical: 16px

		position: relative
		font-size: 13px
		padding: 9px 0

		@media (min-width: $break)
			flex-grow: 1

		&-in
			margin: 0 math.div(-$spacing-vertical, 2)
			display: flex
			justify-content: center
			flex-wrap: wrap

			@media (min-width: $break)
				justify-content: flex-start

		&-item
			color: inherit
			text-decoration: none
			padding: 3px math.div($spacing-vertical, 2)
			cursor: pointer

			@media screen and (max-width: $content-width-narrow)
				font-size: 15px
				padding-top: 7px
				padding-bottom: 7px
				margin-top: 2px

			&:hover,
			&:focus
				text-decoration: underline

	&-attribution
		position: relative
		font-size: 14px
		font-weight: 300
		color: #AAAAAA
		text-align: right
		padding: 8px 0
		padding-top: 14px

		&-link
			color: inherit
			padding-top: 10px
			padding-bottom: 10px

			&:hover,
			&:focus
				text-decoration: none
