@use '../styles/_variables' as *

.shoppingListLink
	color: inherit
	display: inline-block
	font-weight: 800
	font-size: 13px
	text-align: center
	text-decoration: none

	&:hover,
	&:focus
		text-decoration: underline

	&.is-active
		color: $color-primary

	&-icon
		margin-bottom: 5px
		position: relative
		display: inline-block

	&-label
		//

	&-indicator
		position: absolute
		top: -7px
		right: -10px
		font-size: 10px
		font-weight: $font-weight-normal
		background-color: currentColor
		display: inline-flex
		justify-content: center
		align-items: center
		line-height: 1.2
		padding: 0 4px
		border-radius: 8px
		min-width: 16px
		min-height: 16px

		&-in
			color: #FFFFFF
