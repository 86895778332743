@use '../styles/_variables' as *
@use 'swiper/components/effect-fade/effect-fade.scss'

.interactiveHero
	$circle-thickness: 7px
	$root: &

	position: relative
	max-width: 2000px
	width: 100%
	margin-left: auto
	margin-right: auto
	overflow: hidden

	@media (min-width: 500px)
		margin-top: -50px

	@media (min-width: 800px)
		margin-top: -80px

	@media (min-width: 1100px)
		margin-top: -130px

	.swiper-container
		overflow: visible
		padding: 0

	&-picture
		position: relative
		z-index: -1


		@media (max-width: $content-width)
			min-height: 40vw !important

	&-in
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0

	&-fullPageLink
		position: absolute
		top: 0
		left: 0
		width: 100% 
		height: 100%
		pointer-events: all

		a
			display: block
			cursor: pointer 
			width: 100% 
			height: 100%

	&-pointOfInterest
		--interactiveHero-x: -100
		--interactiveHero-y: 0
		position: absolute
		top: calc(var(--interactiveHero-y) * 1%)
		left: calc(var(--interactiveHero-x) * 1%)
		display: none
		justify-content: center
		align-items: center
		width: 0
		height: 0
		pointer-events: auto

		@media (min-width: 1000px)
			display: flex

		&-label
			position: absolute
			z-index: 1
			display: flex
			justify-content: center
			align-items: center
			margin: 3px 6px
			white-space: nowrap
			opacity: 0
			visibility: hidden
			transition-property: opacity, visibility
			transition-duration: 0.2s

			&.view-position-top,
			&.view-position-bottom
				left: 0
				right: 0

			&.view-position-top
				bottom: calc(100% + #{$circle-thickness})

			&.view-position-bottom
				top: calc(100% + #{$circle-thickness})

			&.view-position-left,
			&.view-position-right
				top: 0
				bottom: 0

			&.view-position-left
				right: calc(100% + #{$circle-thickness})

			&.view-position-right
				left: calc(100% + #{$circle-thickness})


		&-button
			$dot-size: 10px

			position: relative
			border: none
			color: $color-primary
			text-transform: uppercase
			font-weight: 400
			font-family: $font-head
			font-size: 18px
			transition: color 0.2s

			&:hover,
			&:focus
				color: $color-primary-alternative
				z-index: 1

				& #{$root}-pointOfInterest-label
					opacity: 1
					visibility: inherit
					transition-delay: 0s

			&::before
				position: absolute
				top: -$circle-thickness - 10px
				left: -$circle-thickness - 10px
				right: -$circle-thickness - 10px
				bottom: -$circle-thickness - 10px
				content: ''

			&::after
				@keyframes interactiveHero-pulse
					to
						transform: scale(0.9)

				content: ''
				display: block
				padding: calc($dot-size / 2)
				width: $dot-size + 2 * $circle-thickness
				height: $dot-size + 2 * $circle-thickness
				background-color: $color-background
				border-radius: 50%
				border: $circle-thickness solid
				animation: interactiveHero-pulse 1.3s calc(-0.7s * var(--interactiveHero-x) * var(--interactiveHero-y)) infinite alternate
