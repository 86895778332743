@use '../styles/_variables' as *

.content
	$root: &

	--content-vertical-gap: 60px

	display: grid
	grid-template-columns: 100%
	gap: var(--content-vertical-gap) 0

	&-share
		display: flex
		justify-content: center

	&.view-innerSize-narrow
		--content-innerSize: #{$content-width-narrow}

	&.view-innerSize-wide
		--content-innerSize: #{$content-width-wide}

	&.view-innerSize-normal
		--content-innerSize: #{$content-width}

	&.view-innerSize-narrow,
	&.view-innerSize-wide,
	&.view-innerSize-normal
		grid-template-columns: minmax(0, 1fr) minmax(0, var(--content-innerSize)) minmax(0, 1fr)

		#{$root}-block
			grid-column: 2

			&.view-stretch
				z-index: 1
				grid-column: 1 / -1
				background-color: $color-background

		#{$root}-share
			grid-column: 1 / -1

			@media (min-width: $break-share)
				position: sticky
				top: 1em
				grid-column: 1
				grid-row: 1

	&-block
		&.view-type-homepageTilesGrid:not(:last-child)
			margin-bottom: calc(-1 * var(--content-vertical-gap))

		&.view-type-homepageInteractiveHero:not(:last-child)
			@media (max-width: 999px)
				margin-bottom: calc(-0.5 * var(--content-vertical-gap))
