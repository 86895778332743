@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'
@use "sass:math"

.arrow
	$thickness: 2px
	$space: 10px
	$root: &

	position: relative
	margin-bottom: -1px
	width: $arrow-width
	height: 12px
	display: inline-block
	z-index: 1

	&::before
		+position.absolute(calc(50% - math.div($thickness, 2)) $thickness null 0)
		content: ''
		height: $thickness
		background-color: currentColor

	&::after
		+position.absolute(50% 0 null null)
		content: ''
		width: 7px
		height: 7px
		display: inline-block
		transform-origin: 100% 0
		transform: rotate(45deg)
		border-top: solid $thickness
		border-right: solid $thickness

	&.view-direction-right
		margin-left: $space

	&.view-direction-left
		margin-right: $space

		&::before
			right: 0
			left: $thickness

		&::after
			right: 100%
			transform: rotate(225deg)

	& + &
		background-color: $color-primary-alternative
