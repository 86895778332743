@use "sass:math"

.radioButtons
	$break: 900px
	$gap: 22px

	// @media (min-width: $break)
	// 	overflow: visible

	&-in
		display: flex
		flex-wrap: wrap 
		gap: $gap

		@media (min-width: $break)
			margin: math.div(-$gap, 2)
			display: flex
			flex-wrap: wrap
			gap: 0

	&-item
		white-space: nowrap

		@media (min-width: $break)
			padding: math.div($gap,  2)

		@media (max-width: $break)
			.button-in 
				padding-left: 10px
				padding-right: 10px