@use '../styles/_variables' as *

.fulltextHitGroup
	$padding-horizontal: 15px

	padding: 20px 0 15px
	line-height: 1.2

	& + &
		border-top: 1px solid #cacaca

	&-title
		font-weight: $font-weight-heavy
		font-family: $font-base
		margin: 0 $padding-horizontal 5px
		font-size: 13px

	&-items
		padding: 0
		margin: 0
		list-style-type: none

	&-item

		&-link
			text-decoration: none
			display: block
			padding: 5px $padding-horizontal

			&:hover,
			&:focus
				background-color: #F5F5F5

			em 
				color: $color-text 
				text-decoration: none 
				font-size: 0.8rem
				font-style: normal
