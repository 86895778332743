@use '../styles/_variables' as *

.videoArticle
	position: relative

	&::before
		content: ''
		display: block
		padding-top: calc(100% / (16 / 9))

	&-in
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
