@use '../styles/_variables' as *

.band
	$root: &

	display: flex
	overflow-x: auto
	overflow-y: hidden
	margin: 5px (-$page-horizontal-spacing) 35px
	padding: 5px 0

	&-in
		display: flex
		flex-grow: 1

		&::before,
		&::after
			content: ''
			width: $page-horizontal-spacing
			flex-shrink: 0
