@use '../styles/_variables' as *

.questionForm
	width: 100%
	display: grid
	gap: 20px
	margin-top: 30px

	@media (min-width: 600px)
		grid-template-columns: repeat(2, 1fr)

		&-field:nth-child(1),
		&-field:nth-child(6),
		&-recaptcha,
		&-submit
			grid-column: 1 / -1

	@media (min-width: 1000px)
		grid-template-columns: repeat(4, 1fr)

	&-recaptcha
		display: flex
		justify-content: center
