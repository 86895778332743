@use '../styles/_variables' as *

@keyframes productVariantPickerExpanded-pulse
	100%
		border-color: transparent


.productVariantPickerExpanded
	$root: &
	$transition-duration: 0.2s

	display: grid 
	// grid-template-columns: 1fr 1fr
	grid-template-columns: 1fr

	gap: 5px
	position: relative

	// background: $color-background-contained
	border-radius: $border-radius
	color: $color-text !important
	padding: 0
	
	&.is-single
		grid-template-columns: 1fr

	@media screen and (max-width: $content-width-narrow)
		grid-template-columns: 1fr

	&-option 
		padding: 0
		padding-right: 8px
		background-color: $color-background
		border: 1px solid $border-color
		border-radius: $border-radius

		&-selected 
			background: rgba($color-primary, 0.15)
			border-color: $color-primary
	

	&::before
		content: ''
		border: 2px solid rgba($color-primary, 0.6)
		border-radius: $border-radius
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		margin: -1px
		pointer-events: none
		opacity: 0
		transition-property: opacity
		transition-duration: $transition-duration * 2


	&.is-highlight::before
		opacity: 1
		animation: productVariantPicker-pulse 0.6s alternate infinite ease-out


	.productVariant-price 
		font-size: 0.9rem
		font-weight: 600
