@use '../styles/_variables' as *

.numberedColumns
	&-title
		margin-bottom: 40px

	&-items
		counter-reset: numberedColumns
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(260px, 1fr))
		gap: 30px

	&-item
		display: flex
		flex-wrap: wrap

		&::before
			margin-right: 16px
			display: inline-block
			counter-increment: numberedColumns
			content: counter(numberedColumns)
			min-width: 36px
			height: 36px
			line-height: 1.2
			border-radius: 18px
			border: 1px solid
			color: $color-primary
			font-size: 26px
			text-align: center
			padding: 5px 3px 0
			font-family: $font-head
			background-color: #E2E2E2

		@media (min-width: 607px)
			display: block

			&::before
				margin-bottom: 15px
