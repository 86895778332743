@use '../styles/_variables' as *

// @TODO: Možné zlepšení: animovat vertikální pohyb spodní čáry.

.collapsible
	$color-active: $color-primary
	$color-inactive: #DBDBDB
	$transition-duration: 0.2s
	$root: &

	position: relative
	--collapsible-color-highlight: #{$color-inactive}
	border-bottom: 1px solid var(--collapsible-color-highlight)
	transition: border-color $transition-duration

	&.is-expanded
		z-index: 1
		--collapsible-color-highlight: #{$color-active}

	@media print 
		&.is-printable
			z-index: 1
			--collapsible-color-highlight: #{$color-active}

	& + &
		margin-top: -1px
		border-top: 1px solid var(--collapsible-color-highlight)

	&:last-child
		border-bottom: none

	&-label
		position: relative
		width: 100%
		text-align: inherit
		border: none
		background: none
		font-size: 15px
		color: inherit
		font-weight: 300
		padding: 1em 0
		display: flex
		align-items: center
		justify-content: space-between
		outline: none
		text-decoration: none

		&:hover,
		&:focus
			--collapsible-color-highlight: #{$color-active}

		#{$root}.view-large &
			font-size: 19px
			font-weight: 500

		&::after
			content: ''
			color: var(--collapsible-color-highlight)
			display: inline-block
			width: 0.75em
			height: 0.75em
			border-top: 2px solid
			border-right: 2px solid
			transform-origin: 100% 0
			transform: translateY(50%) rotate(45deg)
			transition-property: transform, color
			transition-duration: $transition-duration
			flex-shrink: 0
			margin-left: 1em

			#{$root}.is-expanded &
				transform: translate(-74%, 90%) rotate(135deg)

		@media print 
			&::after 
				content: none 

	&-content
		transition: opacity $transition-duration
		opacity: 0
		height: 0
		overflow: hidden

		#{$root}.is-expanded &
			opacity: 1

		#{$root}.is-expanded &,
		#{$root}.is-transitioning &
			height: auto
			overflow: visible

		&-in
			transition: visibility $transition-duration
			visibility: hidden
			padding-bottom: 25px

			#{$root}.is-expanded &
				visibility: inherit

	@media print 
		&.is-expanded:not(.is-printable)
		
			.collapsible-content
				height: 0 !important
				overflow: hidden  !important
				opacity: 0 !important

		&.is-printable
			.collapsible-content
				height: auto !important
				overflow: visible !important
				opacity: 1 !important

				&-in 
					visibility: visible !important


