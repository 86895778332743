@use '../styles/_variables' as *

.openingHours
	text-align: left
	line-height: 1.25

	&-group
		font-weight: $font-weight-heavy

	&-group-title,
	&-option-title
		margin-right: 0.75em
		flex-grow: 1

	&-group-value,
	&-option-value
		font-weight: $font-weight-book
		flex-grow: 1
		text-align: right

	&-group,
	&-option
		display: flex
		flex-wrap: wrap

	&-option
		&.view-special
			color: $color-primary

	&-options
		width: 100%
		margin: 0.1em 0 0.3em

	&-note
		margin-bottom: 0.75em
		font-size: 14px