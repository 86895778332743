// See README.md
=position($position, $properties: null null null null)
	$propertiesLength: length($properties)

	@if length($properties) == 1
		$properties: nth($properties, 1) nth($properties, 1) nth($properties, 1) nth($properties, 1)
	@else if length($properties) == 2
		$properties: nth($properties, 1) nth($properties, 2) nth($properties, 1) nth($properties, 2)
	@else if length($properties) == 3
		$properties: nth($properties, 1) nth($properties, 2) nth($properties, 3) nth($properties, 2)
	@else if $propertiesLength > 4
		@error 'Invalid number of position arguments'

	// One line because the Sass parser is stupid… 🙄
	$offsets: (top: nth($properties, 1), right: nth($properties, 2), bottom: nth($properties, 3), left: nth($properties, 4))

	position: $position

	@each $offset, $value in $offsets
		@if $value != null
			#{$offset}: $value

=absolute($properties: null null null null)
	+position(absolute, $properties)


=fixed($properties: null null null null)
	+position(fixed, $properties)


=relative($properties: null null null null)
	+position(relative, $properties)


=sticky($properties: null null null null)
	+position(sticky, $properties)
