@use '../_variables' as *

=shoppingListButton
	display: inline-flex
	flex-direction: column
	align-items: center
	color: $color-primary
	border: none
	padding: 0
	background-color: transparent
	font-size: 15px

	&:hover,
	&:focus
		text-decoration: underline

	&-icon
		//
