@use '../styles/_variables' as *

.leadText
	font-size: 1.15em

	&:not(.view-insideIntro)
		text-align: center
		margin-bottom: 2em

	@media (min-width: 800px)
		font-size: 1.25em
