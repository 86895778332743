@use '../styles/_variables' as *

.garden
	background-color: $color-primary
	color: $color-text-inverted
	min-height: 100%
	display: flex
	flex-direction: column
	text-align: center
	padding: 70px $page-horizontal-spacing

	@media (min-width: 800px)
		padding-left: 2 * $page-horizontal-spacing
		padding-right: 2 * $page-horizontal-spacing

	@media (min-width: 1400px)
		padding-left: 3 * $page-horizontal-spacing
		padding-right: 3 * $page-horizontal-spacing

	&::before,
	&::after
		content: ''
		flex-grow: 3

	&::after
		flex-grow: 4

	&-title
		margin: 0
		text-transform: uppercase
		font-family: $font-head
		font-size: 44px
		line-height: 58px
		font-weight: $font-weight-normal

		@media (min-width: 600px)
			font-size: 53px

	&-content
		margin-top: 0.5em
		font-size: 19px
		font-weight: $font-weight-book
