@use '../styles/_variables' as *

.breadcrumbs
	padding: 16px $page-horizontal-spacing
	font-size: 13px
	font-weight: 300
	color: #979797

	&-in
		max-width: $content-width
		margin: 0 auto


	&-item, &-sideitem
		display: inline-block
		padding: 4px 0

	&-item:not(:has(a))
		color: $color-primary

	&-separator
		margin-left: 1em
		margin-right: 0.5em
		vertical-align: baseline

	&-link
		color: inherit

		&:hover,
		&:focus
			text-decoration: none

	&-arrow
		margin: 0 2px
		display: inline-block
		width: 6px
		height: 6px
		vertical-align: middle
		border-bottom: 1px solid
		border-right: 1px solid
		transform-origin: 100% 100%
		transform: translateY(-70%) rotate(-45deg)

	@media print
		display: none
