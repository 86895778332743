@use '../styles/_variables' as *

.contactInfoLayout
	--contactInfoLayout-gap-vertical: 30px
	--contactInfoLayout-gap-horizontal: 30px
	padding: 40px 0
	overflow: hidden

	@media (min-width: 1000px)
		--contactInfoLayout-gap-horizontal: 80px

	&-in
		display: flex
		flex-wrap: wrap
		margin: calc(-1 * var(--contactInfoLayout-gap-vertical) / 2) calc(-1 * var(--contactInfoLayout-gap-horizontal) / 2)

	&-main,
	&-aside
		padding: calc(var(--contactInfoLayout-gap-vertical) / 2) calc(var(--contactInfoLayout-gap-horizontal) / 2)

	&-main
		flex-grow: 1

		&-title
			margin: 0 0 1em
			font-size: 25px
			font-weight: $font-weight-heavy

	&-aside
		flex-grow: 1
		flex-basis: 100%
		margin: 0 auto
		max-width: calc(520px + var(--contactInfoLayout-gap-horizontal))
