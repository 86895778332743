@use '../styles/_variables' as *

.widthBound
	padding-left: $page-horizontal-spacing
	padding-right: $page-horizontal-spacing

	container-name: widthBound
	container-type: inline-size

	&-in
		margin: 0 auto

	&.view-size-normal &-in
		max-width: $content-width

	&.view-size-narrow &-in
		max-width: $content-width-narrow

	&.view-size-wide &-in
		max-width: $content-width-wide
