@font-face
	font-family: 'Avenir'
	src: url('/fonts/Avenir-Book.ttf') format('truetype')
	font-weight: 300
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Avenir'
	src: url('/fonts/Avenir-Medium.ttf') format('truetype')
	font-weight: 500
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Avenir'
	src: url('/fonts/Avenir-Heavy.ttf') format('truetype')
	font-weight: 800
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Avenir'
	src: url('/fonts/Avenir-Black.ttf') format('truetype')
	font-weight: 900
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Avenir-Oblique'
	src: url('/fonts/Avenir-Oblique.ttf') format('truetype')
	font-weight: normal
	font-style: oblique
	font-display: swap

@font-face
	font-family: 'DIN-Condensed-Bold'
	src: url('/fonts/DIN-Condensed-Bold.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap
	ascent-override: 60%
	descent-override: 5%

