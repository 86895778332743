.layout
	position: relative
	display: flex
	flex-direction: column
	min-height: 100vh
	overflow-x: hidden

	&-main
		position: relative
		z-index: 1
		flex-grow: 1
		display: flex
		flex-direction: column

	&-subMenuBackground
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		z-index: 2
		width: 100%
		height: 100%
		display: block
		border: none
		background-color: rgba(#000000, 0.54)
		cursor: default
		transition-property: opacity, visibility
		transition-duration: 0.2s
		opacity: 0
		visibility: hidden

		&.is-open
			opacity: 1
			visibility: inherit

	