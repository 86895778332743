@use '../styles/_variables' as *

.productRackList
    display: grid
    gap: 10px
    align-content: start 
    grid-template-columns: 1fr 1fr

    @media screen and (max-width: $content-width-narrow)
        grid-template-columns: 1fr

    &-item
        display: grid 
        grid-template-columns: 130px max-content
        gap: 20px

        overflow: hidden
        border-radius: 4px
        border: 1px solid $border-color
        height: min-content

        &-info 
            padding: 20px 0px
            display: flex 
            flex-direction: column
            gap: 10px
            justify-content: space-between

            span 
                color: $color-primary-alternative
                font-size: 13px

            small 
                color: $color-text-light
                text-transform: capitalize

            h6 
                margin: 0
                margin-bottom: 10px

            svg 
                vertical-align: bottom 
                transform: scale(0.75)