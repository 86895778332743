@use '../styles/_variables' as *

.parameters
	font-size: 15px
	line-height: 1.3
	display: grid
	grid-template-columns: repeat(auto-fill, 260px)
	justify-content: space-between
	gap: 4px 5em
	margin-top: 10px
	background-color: #F2F2F2

	@media screen and (max-width: $content-width)
		gap: 4px 3em
		grid-template-columns: repeat(auto-fill, 200px)
		justify-content: start

	@media screen and (max-width: $content-width-small)
		grid-template-columns: 1fr

	&-item
		display: flex
		justify-content: space-between

		&-label
			margin-right: 0.75em
			font-weight: $font-weight-book
			color: #000000

		&-value
			font-weight: $font-weight-heavy
			color: #AAAAAA
