@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.cardBox
	position: relative
	z-index: 0
	color: $color-text
	text-decoration: none
	min-height: 100%
	display: flex
	font-size: 1rem
	font-family: $font-base
	font-weight: 400
	line-height: 1.2

	h3 
		font-size: 1.17rem

	&-wrapper
		min-height: 100%
		display: flex
		flex-direction: column

		&::after
			content: ''
			flex-grow: 1
			order: 1 // Before last

		a
			position: relative
			z-index: 2

			&:hover,
			&:focus
				text-decoration: none

	&-footer
			margin-top: 18px
			order: 2 // Last

	&-button,
	&-link
		+position.absolute(0)
		z-index: 1

	&-button
		width: 100%
		height: 100%
		border: none
		background-color: transparent

	
	&-active &-button
		border: solid $color-primary 1px
		font-weight: bold 
		color: $color-primary


		&::before
			content: ""
			+position.absolute(0)
			background-color: #259b44
			opacity: 0.16
			z-index: 2

	&-active &-in
		font-weight: bold 
		color: $color-primary

	&-link
		&-text
			display: block

	&-tag
		margin-top: 10px

	&-cross
		display: flex
		background: transparent
		border: none
		z-index: 3
		padding: 0
		margin-left: 1em

	&-head
		display: flex
		justify-content: space-between
		align-items: center

	&-title
		font-family: $font-base
		font-weight: $font-weight-heavy
		margin: 0
		font-size: 17px

	&-date
		margin: 0
		color: $color-text-light
		font-size: 15px
		margin-left: 1em

	&-content
		margin: 10px 0 0

		p
			margin: 0 0 5px

		a
			color: $color-text
			text-decoration: none

			&:hover,
			&:focus
				text-decoration: underline

		.cardBox-textlink
			margin-top: 1rem
			
			a
				color: $color-primary


	&.view-small &-title,
	&.view-small &-content
		font-size: 15px

