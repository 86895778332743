@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

	
.exportButton
	.card-in
		padding: 2px calc(var(--card-horizontal-padding))

	.cardDownload.cardDownload--loading
		.card
			&::before
				content: ""
				+position.absolute(0)
				background-color: #259b44
				opacity: 0.16
				z-index: 2

	.cardDownload:not(.cardDownload--loading)
		.card
			&::after 
				content: attr(data-download-text)
				position: absolute
				bottom: 1px
				line-height: 1rem
				left: 7px
				opacity: 0
				transition: opacity 200ms
				font-size: 0.85rem
				font-weight: 300
				text-align: center
				width: 70px

			.cardDownload-icon-png, .cardDownload-icon-svg
					img
						filter: none
						transition: filter 200ms

			svg
				filter: none
				transition: filter 200ms

		.card:hover 
			border: solid $color-primary 1px
			font-weight: bold 
			color: $color-primary
			cursor: pointer

			.cardDownload-icon-png, .cardDownload-icon-svg
				img
					filter: brightness(0)

			svg
				filter: none
				transition: filter 200ms

			&::before
				content: ""
				+position.absolute(0)
				background-color: #259b44
				opacity: 0.16
				z-index: 2

			&::after 
				opacity: 1
