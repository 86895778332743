@use '../styles/_variables' as *

.popup
	$transition-duration: 0.2s

	border: 1px solid $border-color
	border-radius: $border-radius
	background-color: $color-background
	padding: 11px 15px
	margin: 5px
	pointer-events: auto
	transition-property: opacity, visibility
	transition-duration: $transition-duration
	opacity: 0
	visibility: hidden
	box-shadow: 9px 17px 15px rgba(0, 0, 0, 0.05)

	&.is-open
		opacity: 1
		visibility: inherit
