@use '../styles/_variables' as *

.mapSearch
	margin-top: 30px
	text-align: center

	&-in
		display: grid
		grid-template-columns: 1fr 1fr
		grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))
		gap: 10px 15px
		max-width: 500px
		margin: 0 auto

	&-title
		width: 100%
		margin: 0 0 10px
		font-size: 24px
		grid-column: 1 / -1
		text-align: center

	&-address,
	&-name
		//
