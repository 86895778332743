@use '../styles/_variables' as *

.viewport3d
    height: 100%
    position: relative

    &-inner 
        height: 100%
        position: relative

    &-overlay 
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        z-index: 20
        overflow-x: hidden

        .viewport3d-overlay-loader
            overflow-x: hidden
            display: flex 
            flex-direction: column
            align-items: center 
            justify-content: center
            font-weight: bold 
            color: $color-primary-alternative
            height: 100%
            text-align: center


            &::before 
                content: ""
                height: 8px 
                position: absolute
                bottom: 0
                left: 0
                width: 100% 
                background-color: $color-primary
                z-index: 2

            &::after 
                @keyframes cardDownload-progress
                    0%
                        transform: translateX(-200%)
                    50%
                        transform: translateX(0%) scaleX(0.5)
                    100%
                        transform: translateX(200%)

                content: ""
                height: 7px 
                position: absolute
                bottom: 1px
                left: 0	
                width: 50% 
                z-index: 5
                background-color: $color-primary-alternative
                animation: cardDownload-progress 0.75s linear infinite
                

    &--fullscreen
        &-inner 
            height: 100%
            position: relative
            z-index: 10

        position: fixed 
        left: 0
        top: 0
        right: 0
        bottom: 0
        z-index: 1000

        background: $color-background-contained
