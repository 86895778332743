@use '../styles/_variables' as *

.footer
	$items-spacing: 40px
	$break: 992px

	padding: 70px $page-horizontal-spacing 0
	color: $color-text-inverted
	background-color: $color-background-inverted
	overflow: hidden

	&-in
		max-width: $content-width
		margin: 0 auto

	&-wrap
		margin: 0 (-$items-spacing)
		display: flex
		flex-wrap: wrap

	&-copyright
		font-size: 13px
		color: #727173

	&-item
		width: 100%
		padding: 0 $items-spacing 30px

		&-title
			font-family: $font-base
			font-size: 15px
			margin: 0
			padding: 0 0 8px
			border-bottom: 1px solid #4b4a49

		&-link
			display: block
			text-decoration: none
			margin-top: 10px
			font-size: 13px
			cursor: pointer

			@media screen and (max-width: $content-width-narrow)
				font-size: 15px
				padding-top: 6px
				padding-bottom: 6px
				margin-top: 4px

		&-icon
			display: inline-block
			margin: 10px 10px 0 0

		&-icon,
		&-link
			color: $color-text-inverted

			&:hover,
			&:focus
				color: darken($color-text-inverted, 10)
				text-decoration: underline


	&-logoTemporary
		font-size: 44.95px

	@media (min-width: 600px)
		&-item
			width: 50%

	@media (min-width: 992px)
		&-wrap
			flex-wrap: nowrap

		&-item
			padding: 0
			margin: 0 $items-spacing

	@media print
		display: none
