@use '../styles/_variables' as *

.alert
	$root: &

	color: $color-primary
	background-color: rgba($color-primary, 0.2)
	border-radius: $border-radius
	padding: 14px

	&-title
		font-size: 15px
		font-weight: 800
		margin: 0

		#{$root}.view-large &
			font-size: 19px

	&-content
		font-size: 13px
		font-weight: 300
		line-height: 22px

		#{$root}.view-large &
			font-size: 19px
			line-height: 25px

		p
			margin: 0.5em 0 0.75em

			&:last-child
				margin-bottom: 0
