@use '../styles/_variables' as *

.textColumns
	display: grid
	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr))
	gap: 40px
	text-align: left

	&-item
		//

	&-title
		text-transform: uppercase
		margin: 0 0 0.4em
		font-size: 26px
		font-weight: $font-weight-normal
		font-family: $font-head
		display: flex
		align-items: center
