@use '../styles/_variables' as *

.composition
	position: relative 
	cursor: pointer

	&-title
		text-align: left
		margin: 0 0 3px 0
		color: $color-primary
		transition: color 150ms

	&-image
		margin: 8px auto 8px 0
		max-width: 320px
		background-color: rgba(#000000, 0.1)
		border: transparent 3px solid
		transition: border-color 150ms
		position: relative

		&:after 
			display: block
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			z-index: 2
			pointer-events: none
			transition: background-color 150ms
			background-color: rgba($color-primary, 0)
			content: ""


	&-action
		text-align: left

		&-title
			margin: 0 0 8px

		&-in
			display: inline-grid
			grid-template-columns: 100px auto
			gap: 8px
			padding-bottom: 4rem

	&:hover 
		.composition-image 
			border-color: $color-primary
		
			&:after 
				background-color: rgba($color-primary, 25%)


	
.composition-active
	.composition-image 
		border-color: $color-primary
	
		&:after 
			background-color: rgba($color-primary, 25%)
	
		