@use '../styles/_variables' as *

.shareLink
	position: relative
	
	input 
		background-color: #FDFDFD
		cursor: pointer
		-webkit-box-shadow: 0 0 0px 1000px #FDFDFD inset
		padding-left: 50px
		z-index: 1

	.shareLink-icon
		position: absolute 
		left: 0
		top: 2px
		width: 40px
		height: 50px
		z-index: 2
		display: flex
		justify-content: center 
		align-items: center


	input 
		transition: outline 0.2s

	svg 
		transition: color 0.2s

	&:hover 
		
		input
			outline: rgba($color-primary-alternative, 0.6) 2px solid

		svg 
			color: rgba($color-primary-alternative, 0.6)

	&:active

		input
			outline: rgba($color-primary-alternative, 1) 2px solid

		svg 
			color: rgba($color-primary-alternative, 1)