@use '../styles/_variables' as *

.collapsibleList
	padding: 0 $page-horizontal-spacing

	&-in
		max-width: $content-width
		margin: 0 auto
		background-color: $color-background
		border: 1px solid #E0E0E0
		border-radius: $border-radius
		padding: 9px 30px

		@media (min-width: 800px)
			padding-left: 60px
			padding-right: 60px
