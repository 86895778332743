@use '../styles/_variables' as *

.showroomLayout
	$root: &
	$gap: 20px
	$break: 600px
	$breakMax: $break - 1px

	display: grid
	gap: $gap
	grid-template-columns: 1fr
	text-align: left

	@media (min-width: $break)
		grid-template-columns: 1fr auto 1fr

		&::before
			content: ''
			grid-row: 1 / span 2

		&-contact
			grid-column: 2

	&-announcement
		line-height: 1.3
		font-size: 15px
		color: $color-highlight
		padding-top: 1rem
		padding-bottom: 1rem
		text-align: left


	&-primary
		line-height: 1.3

	&-address
		color: $color-primary
		font-weight: $font-weight-heavy
		font-style: normal

	&-contact
		margin-top: 8px

	&-panorama
		margin-top: 16px
		margin-bottom: 16px
		
		background: $color-background-contained
		width: 100%
		grid-column: 1/-1
		border: solid $border-color 1px
		border-radius: 4px
		// border-bottom: solid $border-color 1px

		&:hover
			background: $color-background
		
		& a 
			color: $color-primary
			font-weight: $font-weight-heavy
			font-style: normal
			display: block 
			padding-top: 24px
			padding-bottom: 24px
			line-height: 30px

			span 
				vertical-align: bottom

			&:before 
				content: ''
				display: inline-block 
				margin-right: 1rem
				margin-left: 1.5rem
				vertical-align: bottom
				width: 30px
				height: 30px
				background-repeat: no-repeat
				background-image: url(/cube-solid.svg)

	&-address,
	&-contact
		a
			color: inherit
			text-decoration: none

			&:hover,
			&:focus
				text-decoration: underline

	@media (min-width: $break)
		&-openingHours
			grid-column: 3
			grid-row: 1 / span 2
			max-width: 280px

		&.has-openingHours
			grid-template-columns: 1fr auto

			&::before
				display: none

			& #{$root}
				&-contact
					grid-column: 1

				&-openingHours
					grid-column: 2

	&-documents,
	&-videos,
	&-gallery,
	&-productRacks
		grid-column: 1 / -1

	&-documents
		margin-top: 30px

	&-videos
		margin-top: 30px

	&-gallery, &-productRacks
		margin-top: 15px
		border-top: 1px solid #C5C5C5
		padding-top: 20px

	&-productRacks

		.productRackList:has(.productRackList-item:only-of-type)
			display: flex
			justify-content: center

			.productRackList-item
				width: 50%

				@media screen and (max-width: $content-width-narrow)
					width: 100%

