@use '../styles/_variables' as *

.pagination
	display: flex
	justify-content: center
	align-items: center
	color: #AAAAAA
	font-size: 15px
	font-weight: 800

	&.is-loading
		opacity: 0.5

	&-previous,
	&-next
		color: inherit
		width: 18px
		height: 18px
		position: relative

		&::before
			content: ''
			position: absolute
			bottom: 50%
			right: 75%
			width: 6px
			height: 6px
			transform-origin: 100% 100%
			transform: rotate(135deg)
			border-bottom: 2px solid
			border-right: 2px solid

	&-next
		transform: scaleX(-1)

	&-pages
		display: flex
		flex-wrap: wrap
		justify-content: center

	&-page,
	&-ellipsis
		color: inherit
		text-decoration: none
		padding: 8px
		text-align: center

	&-previous,
	&-next,
	&-page
		&:hover,
		&:focus
			color: $color-text

	&-page
		&.is-current
			color: $color-primary

	&-ellipsis
		//
