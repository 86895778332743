@use '../styles/_variables' as *

.galleryGrid
	--galleryGrid-columns-count: 2
	--galleryGrid-aspect-ratio: #{calc(480 / 300)}
	display: grid
	grid-template-columns: 1fr
	gap: 3px

	@media (min-width: 600px)
		grid-template-columns: repeat(var(--galleryGrid-columns-count), 1fr)

	@media (min-width: 800px)
		&.view-shrinkSingle
			grid-template-columns: calc(100% / 3)

	&-item
		position: relative
		background-color: rgba(#000000, 0.05)

		&::before
			content: ''
			display: block
			padding-top: calc(100% / var(--galleryGrid-aspect-ratio))

		&:first-child:last-child::before
			display: none

		&-button
			display: block
			width: 100%
			border: none
			padding: 0
			background-color: transparent

		&-in
			transition: opacity $gallery-hover-transition-duration

		&:hover &-button &,
		&:focus &-button &
			&-in
				opacity: $gallery-hover-opacity

	&.view-noCrop
		//
