@use '../styles/_variables' as *

.wysiwyg
	line-height: 1.4

	b, strong, em
		font-weight: bold
		font-style: normal

	i
		font-style: italic

	u
		text-decoration: underline

	hr
		margin: 2em 0
		padding: 0
		border: none
		height: 1px
		opacity: 0.15
		background-color: currentColor

	s
		text-decoration: line-through

	a
		color: $color-primary
		text-decoration: underline

		&:hover,
		&:focus
			text-decoration: none

	code, kbd, samp
		font-family: monospace
		font-size: 1em

	> *:first-child
		margin-top: 0

	> *:last-child
		margin-bottom: 0

	> div:first-child > *:first-child
		margin-top: 0

	> div:last-child > *:last-child
		margin-bottom: 0
