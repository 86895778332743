@use '../styles/_variables' as *
@use 'sass:math'

.modal
	$offset-vertical: 40px
	$header-height: 40px

	position: relative
	max-width: $content-width
	width: 100%
	max-height: 100%
	margin: 0 auto
	display: flex
	flex-direction: column
	outline: none

	&.view-width-normal
		max-width: $content-width

	&.view-width-narrow
		max-width: $content-width-narrow

	> *
		flex-grow: 1

	& &-content
		overflow: auto
		overscroll-behavior-y: contain
		-webkit-overflow-scrolling: touch
		border-radius: $border-radius
		background-color: $color-background
		flex-grow: 1
		pointer-events: auto
		display: flex

	&-overlay
		position: fixed
		top: 0
		left: 0
		right: 0
		bottom: 0
		z-index: 3
		display: flex
		flex-direction: column
		justify-content: flex-start
		background-color: rgba(#000000, 0.88)
		padding: ($offset-vertical + $header-height) $page-horizontal-spacing $offset-vertical

		&::before,
		&::after
			content: ''
			flex-grow: 1

		&::after
			flex-grow: 2

	&-close
		$offset: 10px

		position: absolute
		top: -$offset - $header-height
		right: -$offset
		z-index: 1
		width: 20px + 2 * $offset
		height: 20px + 2 * $offset
		border: none
		background-color: transparent
		color: $color-text-inverted

		&:hover,
		&:focus
			color: darken($color-text-inverted, 10%)

		&:active
			transform: translateY(1px)

		&::before,
		&::after
			$width: 2px

			content: ''
			position: absolute
			top: calc(50% - #{math.div($width, 2)})
			left: $offset * 0.8
			right: $offset * 0.8
			height: $width
			background-color: currentColor
			transform: rotate(45deg)

		&::after
			transform: rotate(-45deg)
