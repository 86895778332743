@use '../styles/_variables' as *

.linkSlider
	&-item
		padding: 0 15px

		&-in
			position: relative
			width: 300px
			height: 300px

	.swiper-pagination-bullets
		justify-content: center !important
