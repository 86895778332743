@use '../../styles/_variables' as *

.homepageSearch
	$color-border: #D6D6D6
	$border-radius: 3px

	position: relative
	z-index: 1

	&-hits
		position: absolute
		top: 100%
		left: 0
		right: 0
		background-color: $color-background
		border-left: 1px solid $color-border
		border-right: 1px solid $color-border
		border-bottom: 1px solid $color-border
		border-bottom-left-radius: $border-radius
		border-bottom-right-radius: $border-radius

		&::before
			content: ''
			position: absolute
			top: -2px
			left: -1px
			right: -1px
			height: 3px
			border-left: 1px solid $color-border
			border-right: 1px solid $color-border

		&::after
			content: ''
			position: absolute
			top: -52px
			left: -1px
			right: -1px
			bottom: 0
			box-shadow: 0 8px 12px rgba(0, 0, 0, 0.23)
			pointer-events: none

		&:empty
			display: none
