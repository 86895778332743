@use '../styles/_variables' as *

.file-upload-input
    position: relative
    $size: 35px 

    .file-upload-input-close
        position: absolute
        cursor: pointer  
        z-index: 10
        text-align: center
        line-height: $size 

        width: $size
        height: $size
        transition: background-color 0.3s
        border-radius: 25%
        top: calc(50% - $size / 2.0)
        vertical-align: middle
        right: $size / 2.0

        background-color: $color-background
        &:hover 
            background-color: $color-background-contained
    
