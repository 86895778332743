@use '../styles/_variables' as *
@use "sass:math"

.compositionsLayout
	text-align: left

	&-in
		display: grid
		grid-template-columns: repeat(4, 1fr)
		grid-auto-flow: row dense
		gap: 20px

		@media screen and (max-width: $content-width-narrow)
			grid-template-columns: repeat(2, 1fr)

		// @media screen and (max-width: $content-width-small)
		// 	grid-template-columns: 1fr

	& .composition-list 
		grid-column: 4 span

		@media screen and (max-width: $content-width-narrow)
			grid-column: 2 span

		// @media screen and (max-width: $content-width-small)
		// 	grid-column: 1

	.productList
		max-width: calc(100vw - 40px)
