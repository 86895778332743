@use '../styles/_variables' as *

.departmentList
	&-group
		display: grid
		gap: 15px
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))

		&-item
			//
