@use '../styles/_variables' as *

.tabsPanel
	$root: &

	display: flex
	flex-direction: column
	pointer-events: none

	&-header
		position: relative
		width: 100%

		&::before
			content: ''
			position: absolute
			top: 100%
			left: 0
			display: block
			width: $border-radius
			height: $border-radius
			background-color: $color-background

	&-content
		background-color: $color-background
		pointer-events: auto
		width: 100%

		&-in
			padding: 50px 0

	&-item
		animation: 300ms tabsPanel-fadeIn
		max-width: $content-width + 2 * $page-horizontal-spacing
		padding: 0 $page-horizontal-spacing
		margin: 0 auto
		text-align: center

		&-wide 
			max-width: $content-width-wide + 2 * $page-horizontal-spacing

		&-left 
			text-align: left

	&.view-modalHeader
		margin-top: -40px
		height: 100%

	&:not(.view-modalHeader) &
		&-content
			border-top: 1px solid #c5c5c5
			background-color: $color-background-contained

		&-header::before
			display: none

@keyframes tabsPanel-fadeIn
	from
		opacity: 0
	to
		opacity: 1
