@use '../styles/_variables' as *

.swimmingPoolConfiguratorLayout
	$narrow-width: 500px
	$break: 1000px
	$breakMax: $break - 1px

	.swimmingPoolConfiguratorLayout-header
		// border-bottom: solid 1px $color-text 
		display: flex 
		gap: 5px 
		justify-content: center

		@media screen and (max-width: $content-width-narrow)
			flex-wrap: wrap

	.swimmingPoolConfiguratorLayout-track
		display: flex 
		justify-content: space-between
		a 
			cursor: pointer
			
			strong
				font-size: 3rem
				vertical-align: baseline 
				position: relative 
				top: 7px

				&:first-child 
					margin-right: 5px 

				&:last-child 
					margin-left: 5px
				
			

			&:hover 
				span
					text-decoration: underline

	.swimmingPoolConfiguratorLayout-headerstate
		display: flex
		align-items: center
		color: $color-text-light

		strong 
			font-size: 4rem
			margin-right: 1rem

		&.active 
			color: $color-primary

		@media screen and (max-width: $content-width-narrow)
			width: 78%

	.swimmingPoolConfiguratorLayout-headerstatearrow
		font-size: 4rem
		text-align: center
		width: 60px
		display: inline-block
		color: $color-text-light
		position: relative 
		top: -5px

		@media screen and (max-width: $content-width-narrow)
			width: 20%

		@media screen and (min-width: $content-width-narrow)
			&:last-child
				display: none


	.swimmingPoolConfiguratorLayout-state
		overflow-y: hidden 
		max-height: 0
		height: 0
		//transition: max-height 800ms ease-out

		&.swimmingPoolConfiguratorLayout-show	
			max-height: 10000px	
			height: auto

	.swimmingPoolConfiguratorLayout-shape

		.swimmingPoolConfiguratorLayout-svg
			box-sizing: border-box
			min-height: 400px
			padding: 30px
			display: flex 
			align-items: center

			svg 
				rotate: 30deg

		.swimmingPoolConfiguratorLayout-shapeGrid
			display: grid 
			gap: 20px 
			grid-template-columns: repeat(4, 1fr)

			.cardBox

				transition: border-color 200ms
				&.active, &:hover
					cursor: pointer

				.cardBox-title
					min-height: 3rem

				

			@media screen and (max-width: $break)
				grid-template-columns: repeat(2, 1fr)

				.swimmingPoolConfiguratorLayout-svg
					min-height: 450px

			@media screen and (max-width: $narrow-width)
				grid-template-columns: repeat(1, 1fr)

	.swimmingPoolConfiguratorLayout-variant
		.swimmingPoolConfigurator-variantFlex
			display: grid 
			grid-template-columns: 60fr 40fr
			gap: 40px
			grid-auto-flow: row dense

			@media screen and (max-width: $break)
				grid-template-columns: 50fr 50fr

			@media screen and (max-width: $content-width-narrow)
				grid-template-columns: 1fr

			.swimmingPoolConfiguratorLayout-variantButtons
				grid-column: 2 span
				display: flex 
				justify-content: flex-end
				gap: 20px

				@media screen and (max-width: $content-width-narrow)
					grid-column: 1
					grid-row: 2

			.swimmingPoolConfiguratorLayout-variantButtons
				@media screen and (max-width: $content-width-narrow)
					grid-row: 3
					grid-column: 1

				@media screen and (max-width: $content-width-small)
					flex-direction: column-reverse

				
		.swimmingPoolConfiguratorLayout-variantGrid
			gap: 20px
			display: grid

			.cardBox
				min-height: unset
			
	.swimmingPoolConfiguratorLayout-svg
		position: relative
		svg 
			path
				z-index: 5
				stroke: $color-primary
				fill: rgba($color-primary, 0.3)
				stroke-width: 30px

				&.even
					stroke: $color-text
					fill: rgba($color-text, 0.3)

				&.spacer 
					stroke-width: 50px
					stroke: $color-text-light

				&.dimension
					fill: none	
					z-index: 4
					stroke: $color-text-light

			text.dimension 
				font-size: 400px
				fill: $color-text-light

				&.dimension-large
					font-size: 800px


	.swimmingPoolConfiguratorLayout-dimensions

		.swimmingPoolConfiguratorLayout-dimensionsButtons
			display: flex
			justify-content: flex-end
		.swimmingPoolConfiguratorLayout-dimensionsFlex
			display: flex
			gap: 20px

			.swimmingPoolConfiguratorLayout-svg
				width: 60%

				svg 
					max-height: 900px
					path
						stroke-width: 10px

						&.spacer
							stroke-width: 55px

			.swimmingPoolConfiguratorLayout-dimensionsInputs
				width: 40%
				display: flex 
				flex-direction: column
				gap: 20px

				input.error 
					background-color: lightcoral
					-webkit-box-shadow: 0 0 0px 1000px lighten(lightcoral, 20%) inset

			@media screen and (max-width: $break)
				flex-direction: column-reverse

				.swimmingPoolConfiguratorLayout-dimensionsInputs
					width: 100%

				.swimmingPoolConfiguratorLayout-svg
					width: 100%

	.swimmingPoolConfiguratorLayout-final

		.swimmingPoolConfiguratorLayout-finalFlex
			display: flex
			gap: 30px

			&>div:first-child
				width: 65%
				display: flex 
				justify-content: space-between
				flex-direction: column
				height: 100%
				gap: 20px

				p
					span 
						color: $color-primary

					&:nth-of-type(2)
						line-height: 1.3

				&>div:last-child p 
					margin-bottom: 0					

			&>div:last-child
				width: 35%

			@media screen and (max-width: $content-width-narrow)
				flex-direction: column
				align-items: flex-end

				&>div:first-child
					width: 100%

				&>div:last-child
					width: 100%

		.swimmingPoolConfiguratorLayout-finalFlexShare
			display: flex 
			gap: 20px
			align-items: flex-end
			
			.shareLink
				width: 100%

			@media screen and (max-width: $content-width-narrow)
				flex-wrap: wrap
				gap: 30px

		.swimmingPoolConfiguratorLayout-svg
			padding-top: 150px
			padding-bottom: 150px
			border: double 6px $color-text-light
			position: relative

			path 
				stroke-width: 5px

			text.dimension
				font-size: 200px

			.swimmingPoolConfiguratorLayout-svgTable
				height: 100px
				position: absolute 
				right: 0
				bottom: 0
				border-left: double 6px $color-text-light
				border-top: double 6px $color-text-light
				border-collapse: collapse
				min-width: 35%
				
				tr 
					
					td, th 
						padding: 10px
						font-size: 1rem
						text-align: left
						border: 1px solid $color-text-light

					th 
						text-transform: uppercase

			@media screen and (max-width: $content-width-narrow)
				.swimmingPoolConfiguratorLayout-svgTable
					display: none

		.band 
			margin: 0
			padding: 0

			&-in:after, &-in:before 
				display: none

	@media print
		font-size: 11px !important

		.swimmingPoolConfiguratorLayout-finalFlexShare
			display: none !important

		.spacer
			margin-top: 20px

		.productList
			width: 100%
			box-sizing: border-box

			.productListRow
				width: 100%

				td 
					padding: 5px 17px

			*
				box-sizing: border-box
				font-size: 11px !important


		.swimmingPoolConfiguratorLayout-header,
		.swimmingPoolConfiguratorLayout-track,
		.swimmingPoolConfiguratorLayout-finalFlexInner
			display: none !important

		.swimmingPoolConfiguratorLayout-mobile
			display: none

		.swimmingPoolConfiguratorLayout-svg
			page-break-before: always
			page-break-inside: avoid
			
			width: 100%
			height: 120vh
			overflow: hidden
			margin: 0
			padding: 0
			box-sizing: border-box


			position: relative

			svg
				position: absolute
				top: 0
				width: 100%
				height: 110vh

				path.even, path.odd
					fill: none !important
					stroke: black

				text 
					fill: black 
					stroke: none
					font-family: monospace

			.swimmingPoolConfiguratorLayout-svgTable	
				height: 60px !important

				tr 
					td, th 
						font-family: monospace
						font-size: 11px !important

		.spacer:last-child
			display: none

@media print 
	.swimmingPoolConfigurator-intro
		display: none

	.swimmingPoolConfiguratorLayout .widthBound-in
		max-width: unset !important