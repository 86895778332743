.news
	padding-top: 25px

	&-items
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(275px, 1fr))
		gap: 44px
		padding-bottom: 31px

	&-item
		//
