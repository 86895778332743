@use '../styles/_variables' as *
@use "sass:math"

.attributes

	display: flex
	gap: 7px
	justify-content: flex-end

	@media screen and (max-width: $content-width-narrow)
		flex-wrap: wrap
		justify-content: flex-start 

	&-item

		@media screen and (max-width: $content-width-small)
			&:first-child 
				margin-left: 0

		&-in
			width: 31px
			height: 31px
			background-color: #B2B2B1
			color: $color-text-inverted
			display: block
