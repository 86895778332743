@use '../_variables' as *

=genericForm
	$gap-horizontal: 12px
	$gap-vertical: 15px

	&-about
		display: grid
		gap: $gap-vertical $gap-horizontal
		grid-template-columns: repeat(auto-fit, minmax(260px - $gap-horizontal, 1fr))

		&-field
			//

	&-message
		margin-top: $gap-vertical

	&-info
		margin-top: $gap-vertical
		font-size: 12px
		font-weight: $font-weight-book
		color: #AAAAAA

	&-submit
		margin-top: $gap-vertical

	&-recaptcha
		margin-top: $gap-vertical
		display: flex
		justify-content: center
