@use '../styles/_variables' as *
@use "sass:math"

.lightTabs
	$gap-vertical: 10px
	$gap-horizontal: 40px

	overflow: hidden
	padding-top: 20px

	&-in
		margin: math.div(-$gap-vertical, 2) math.div(-$gap-horizontal, 2)
		display: flex
		flex-wrap: wrap
		align-items: flex-end

	&-item,
	&-aside
		margin: math.div($gap-vertical, 2) math.div($gap-horizontal, 2)

	&-item
		position: relative
		text-transform: uppercase
		font-family: $font-head
		font-size: 19px
		font-weight: $font-weight-normal
		opacity: 0.37
		color: $color-text
		text-decoration: none
		padding: 6px 0
		white-space: nowrap

		&.is-active
			color: $color-primary
			opacity: 1

			&::after
				content: ''
				position: absolute
				left: 0
				right: 0
				bottom: 0
				border-bottom: 5px solid
		
		&.is-disabled
			text-decoration: line-through	

	&-aside
		padding-bottom: 7px
		padding-left: 20px
		flex-shrink: 0
		flex-grow: 1
		display: flex
		justify-content: flex-end
