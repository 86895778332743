@use '../../styles/_variables' as *

.fulltext-page-hit-list 
    //display: grid 
    //grid-template-columns: 1fr

    .fulltext-page-hit
        margin-top: 10px

        &>span
            display: inline-block
            padding-bottom: 5px
            font-size: 0.9rem
            font-weight: bold

        footer 
            position: absolute
            right: 20px 
            top: calc(50% - 0.5rem) 
            color: $color-text-inverted-bland

            @media screen and (max-width: $content-width-narrow)
                position: static 
                margin-top: 20px
                //font-size: 0.95rem
                

            svg
                position: relative
                top: 1px
                margin-left: 10px
                color: $color-primary

        .card:not(:hover)
            border-color: transparent

        .card-in
            padding: calc(var(--card-vertical-padding) / 2) calc(var(--card-horizontal-padding) / 2)

            .cardBox-head
                color: $color-primary

            .cardBox-content
                margin: 5px 0 0

            