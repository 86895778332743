@use '../styles/_variables' as *

.horizontalLine
	height: 1px
	background-color: #DCDCDC
	margin: 0
	width: 100%
	display: block
	border: none
	padding: 0
