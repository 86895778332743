@use '../styles/_variables' as *

.staircaseConfiguratorLayout
	$narrow-width: 500px
	$break: 1000px
	$breakMax: $break - 1px

	@media screen 
		.staircaseConfiguratorLayout-thumbs
			height: 0
			overflow-y: hidden
	
	text-align: left !important

	input.error 
		background-color: lightcoral
		-webkit-box-shadow: 0 0 0px 1000px lighten(lightcoral, 20%) inset

	input[readonly]
		outline: none
		border: none

	.text-primary
		color: $color-primary-alternative

	.staircaseConfiguratorLayout-state

		small 
			color: $color-primary-alternative
			display: inline-flex
			align-items: center
			gap: 0.5rem
			margin-top: 0.5rem

		.staircaseSVG 
			display: block 
			width: calc(100% - 20px)
			height: auto

			.cls-1
				fill: #fff

			.cls-2
				fill: #999

			.cls-3
				fill: #ccc
		
			.cls-4
				fill: none
				stroke: #231f20
				stroke-linejoin: round 
				stroke-width: 1.17px
			&>g 
				opacity: 0.15

		header
			position: relative 
			
			.number 
				font-size: 1.2rem
				line-height: 45px 
				width: 40px
				height: 40px 
				display: inline-block 
				background: $color-primary
				color: $color-text-inverted
				border-radius: 20px
				z-index: 10
				text-align: center
				margin-right: 10px
				vertical-align: middle

		.grid--price
			margin-top: 40px
			margin-bottom: 40px
			padding-bottom: 20px 
			font-size: 1.1rem 
			border-bottom: dotted 3px $color-primary

			table 
				width: 100%
				
				td:last-child 
					padding-left: 2rem
				tfoot tr:first-child 
						td
							padding-top: 2rem

			@media screen and (max-width: $content-width-narrow)
				font-size: 1rem

				table 
					td:last-child 
						padding-left: 0.5rem
						padding-bottom: 1rem

					td:first-child
						padding-right: 2rem
						padding-bottom: 1rem

					tr:last-child td 
						padding-bottom: 0

					

		.grid--image
			display: grid
			gap: 40px 
			grid-template-columns: 70% 30%
			position: relative
			// align-items: center

			@media screen and (max-width: $content-width-narrow)
				grid-template-columns: 1fr
			
				&>*:first-child 
					grid-row: 2				

			h3 
				margin-bottom: 10px

			.grid--selection
				display: grid 
				gap: 20px
				grid-template-columns: 1fr 1fr

				@media screen and (max-width: $content-width-small)
					grid-template-columns: 1fr

		.grid--samplers
			display: grid 
			gap: 20px
			grid-template-columns: 1fr 1fr 1fr

			@media screen and (max-width: $content-width-small)
				grid-template-columns: 1fr
			
			.flex 
				display: grid 
				gap: 20px
				grid-template-columns: 1fr 5fr
				//align-items: center

				h3 
					margin-top: 3px 
					margin-bottom: 3px

				img 
					object-position: cover
					width: 70px !important
					height: 70px !important
					min-height: unset
					max-height: unset
					min-width: unset 
					max-width: unset

				@media screen and (max-width: 900px)
					grid-template-columns: 1fr 4fr


			@media screen and (max-width: 900px)
				grid-template-columns: 1fr 1fr
			
			@media screen and (max-width: 600px)
				grid-template-columns: 1fr
			
				
			
		&.staircaseConfiguratorLayout-steps
			.staircaseSVG 
				g#stupen2, g#stupen3 
					opacity: 1

		&.staircaseConfiguratorLayout-stringers
			.staircaseSVG 
				g#schodnice
					opacity: 1

		&.staircaseConfiguratorLayout-landings
			.staircaseSVG 
				g#podesta
					opacity: 1

		&.staircaseConfiguratorLayout-supports
			.staircaseSVG 
				g#podpera
					opacity: 1

		&.staircaseConfiguratorLayout-final

			table 
				width: 100%	
				margin-bottom: 3rem

				td, th 
					font-size: 1rem

				th:nth-child(3)
					color: $color-primary-alternative

				td:nth-child(3)
					strong
						color: $color-primary-alternative

				td:nth-child(3), td:nth-child(4)
					text-align: right

				th 

					&:first-child
						padding-top: 1rem

					&:nth-child(3), &:nth-child(4)
						text-align: right

				td:first-child 
					padding-left: 50px

				tfoot 
					tr 
						vertical-align: bottom


				@media screen and (max-width: $content-width-narrow)
					table-layout: fixed
					tbody 
						position: relative

					tr.main 
						display: flex
						width: 300%
						padding-top: 2rem

						th 
							display: block
							padding-left: 5px 
							padding-right: 5px

						td 
							display: none

					tr:not(.main)

						th, td 
							padding: 0
							padding-top: 1rem
							padding-left: 5px 
							padding-right: 5px
							font-size: 0.9rem
						
						th:first-child,td:first-child
							text-align: left 

				
			.staircaseSVG 
				g 
					opacity: 1 !important

			.staircaseConfiguratorLayout--share
				display: flex 
				gap: 20px

				&>*:first-child
					flex: 1

				@media screen and (max-width: $content-width-narrow)
					flex-direction: column


			.contactForm
				max-width: 750px

@media print 

	.staircaseConfiguratorLayout-thumbs
		display: flex
		gap: 30px
		align-items: flex-end
		flex-wrap: wrap
		padding-top: 30px

		&>div 
			width: 90px
			border-radius: 2px

			img
				width: 90px
				height: 90px

		&>div:first-child
			width: 140px
			padding-right: 30px

			img
				width: 140px
				height: 140px

		p
			text-align: center

		
	.staircaseConfiguratorLayout-export
		.intro, .tabs, .spacer
			display: none

	.staircaseConfiguratorLayout-state:not(.staircaseConfiguratorLayout-final)
		display: none

	.staircaseConfiguratorLayout--share, .staircaseConfiguratorLayout--share+h3
		display: none !important

	.contactForm
		display: none

	.staircaseConfiguratorLayout-final 
		page-break-before: always

		h2 
			display: none
		table 
			margin-bottom: 0 !important

	.staircaseSVG
		max-height: 150px