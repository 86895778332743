$font-base: 'Avenir', sans-serif
$font-head: 'DIN-Condensed-Bold', sans-serif

$font-weight-book: 300
$font-weight-normal: 400
$font-weight-medium: 500
$font-weight-heavy: 800
$font-weight-black: 900

$color-primary: #299B49
$color-primary-alternative: #188537
$color-highlight: #F07419

$color-text: #242321
$color-text-inverted: #FFFFFF
$color-text-inverted-bland: #737271
$color-text-light: #AAAAAA
$color-background: #FFFFFF
$color-background-inverted: #252221
$color-background-contained: #F2F2F2

$color-footer-divider: #565655

$content-width: 1228px
$content-width-narrow: 680px
$content-width-small: 420px
$content-width-wide: 1600px
$page-horizontal-spacing: 20px

$border-radius: 4px
$border-color: #D6D6D6

$arrow-width: 18px
$arrow-width-and-space: $arrow-width + 10px

$break-header: 1250px
$break-share: 900px

$gallery-hover-opacity: 0.8
$gallery-hover-transition-duration: 0.2s
