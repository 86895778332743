@use '../styles/_variables' as *

$breakpoint: 1000px

.productRackMap
    display: grid
    grid-template-columns: 320px auto 
    gap: 20px
    max-width: 100%

    @media screen and (max-width: $breakpoint)
        grid-template-columns: 1fr

    .productRackList
        grid-template-columns: 1fr

        @media screen and (max-width: $breakpoint)
            grid-template-columns: 1fr 1fr

        @media screen and (max-width: $content-width-narrow)
            grid-template-columns: 1fr

        


    &-map

        aspect-ratio: 16/10
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1)
        border-radius: 4px
        border: 1px solid $border-color
        background-color: $color-background-contained
        position: relative

        @media screen and (max-width: $content-width-small)
            aspect-ratio: 10/16

        &-background 
            filter: blur(10px) brightness(1)

        &-overlay 
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column

            p 
                max-width: 50%
                text-align: center

        &--loading &-overlay 
            .button
                cursor: wait

        &-in 
            height: 100%

        &--loading &-in 
            visibility: collapse

        .gm-style-iw
            padding: 0
            border-radius: 0 !important
            background-color: transparent
            border: none !important

            .gm-style-iw-chr
                display: none

            & > div
                overflow: initial !important

            & > button
                display: none !important
