@use '../styles/_variables' as *

.input
	$root: &
	$border-width: 1px
	$line-height: calc(4em / 3)
	$border-radius: 3px
	$width-submitButton: calc(2 * (var(--input-padding-horizontal) + #{$line-height}))
	$transition-duration: 0.2s

	position: relative
	display: block
	font-size: 15px
	line-height: $line-height
	text-align: left
	--input-padding-horizontal: 1em
	--input-padding-vertical: 1em

	&.view-small
		font-size: 13px

	&.view-micro
		font-size: 12px

	&.view-condense
		--input-padding-horizontal: 0.5em
		--input-padding-vertical: 0.5em

	&-label
		position: absolute
		top: $border-width
		left: $border-width
		right: $border-width
		padding: var(--input-padding-vertical) var(--input-padding-horizontal)
		pointer-events: none
		color: rgba($color-text, 0.5)
		transition-property: transform
		transition-duration: $transition-duration

		#{$root}-control:focus:not(:read-only) ~ &
			color: $color-text

		&-in
			--input-padding-in-horizontal: calc(var(--input-padding-horizontal) / 3)

			position: relative
			z-index: 0
			padding-left: var(--input-padding-in-horizontal)
			padding-right: var(--input-padding-in-horizontal)
			margin-left: calc(-1 * var(--input-padding-in-horizontal))
			transition-property: font-size
			transition-duration: $transition-duration
			text-shadow: 1px 0 1px $color-background, -1px 0 1px $color-background, 0 1px 1px $color-background, 0 -1px 1px $color-background

			&::before,
			&::after
				content: ''
				position: absolute
				z-index: -1
				top: 50%
				left: var(--input-padding-in-horizontal)
				right: var(--input-padding-in-horizontal)
				bottom: 0
				background-color: $color-background

			&::after
				left: 0
				right: 0
				background-color: transparent
				background-image: linear-gradient(to right, rgba($color-background, 0), $color-background var(--input-padding-in-horizontal), $color-background calc(100% - var(--input-padding-in-horizontal)), rgba($color-background, 0))

	&-control
		width: 100%
		display: block
		padding: var(--input-padding-vertical) var(--input-padding-horizontal)
		line-height: inherit
		font-size: inherit
		border: $border-width solid #D6D6D6
		border-radius: $border-radius
		outline-offset: 0

		&,
		&:-webkit-autofill
			-webkit-text-fill-color: $color-text
			background-color: $color-background
			-webkit-box-shadow: 0 0 0px 1000px $color-background inset

		&::placeholder
			opacity: 0

		&:read-only
			outline: none

		&:not(:placeholder-shown),
		&:focus:not(:read-only)
			& ~ #{$root}-label
				transform: translateY(-53%)

				& #{$root}-label-in
					font-size: 0.8em

		&[type="number"]
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button
				-webkit-appearance: none
				margin: 0

		&[type="search"]
			-webkit-appearance: none

			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration
				display: none

		#{$root}.view-multiline &
			min-height: 160px
			resize: vertical

		#{$root}.view-submit &
			padding-right: $width-submitButton

		#{$root}.view-align-left &
			text-align: left

		#{$root}.view-align-right &
			text-align: right

		#{$root}.view-align-center &
			text-align: center

	&-submit
		position: absolute
		top: 0
		right: 0
		bottom: 0
		padding: 0
		width: $width-submitButton
		background-color: $color-primary
		color: $color-text-inverted
		border: none
		border-top-right-radius: $border-radius
		border-bottom-right-radius: $border-radius
		display: flex
		justify-content: center
		align-items: center

		#{$root}.view-submit-bland &
			background-color: transparent
			color: $color-primary

input.error 
	background-color: lightcoral
	-webkit-box-shadow: 0 0 0px 1000px lighten(lightcoral, 20%) inset
