@use '../styles/_variables' as *

.staircaseStepsConfiguratorLayout
	$narrow-width: 500px
	$break: 1100px
	$breakMax: $break - 1px

	&-inner
		text-align: left

		&>h2 
			display: none

		&-perex
			padding-bottom: 2rem

	input[readonly]
		outline: none
		border: none

	h1 
		display: none

	.productVariant
		padding: 0
		.productVariant-icon
			border-radius: $border-radius
			width: 35px 
			height: 35px
			min-height: unset

	.collapsible
		background-color: $color-background
		padding: 0px 20px
		margin-bottom: 10px

		.collapsible-label
			padding: 0

		h2 
			font-family: $font-base
			font-size: 19px
			font-weight: 500

		&.is-expanded h2, &:hover h2
			color: $color-primary-alternative

	.number 
		font-family: $font-head
		font-size: 1rem
		line-height: 36px
		width: 29px
		height: 29px 
		display: inline-block 
		background: $color-primary
		color: $color-text-inverted
		border-radius: 14.5px
		z-index: 10
		text-align: center
		margin-left: 10px
		vertical-align: middle

		position: relative
		top: -2px

		&[data-count="0"]
			opacity: 0

		@media screen and (max-width: $content-width-narrow)
			display: block
			top: 5px
			margin-left: 0
			min-height: 30px


	.productTable
		display: grid
		grid-template-columns: minmax(0, 1fr)
		overflow-x: auto
		overflow-y: visible 
		width: 100%

		table
			border-spacing: 0
			min-width: 100%
			width: auto
			
			margin-top: 2rem
			thead 

				tr 
					border-top-left-radius: 4px 
					border-top-right-radius: 4px
					background: #e3e3e3
					
				th, td
					background: #e3e3e3
					border: #e3e3e3 2px solid
					padding: 26px 17px 10px
					font-size: 12px 
					font-weight: 300

					&:first-child
						border-top-left-radius: 4px 
						text-align: left

					&:last-child
						border-top-right-radius: 4px

					&.variant, &.dimension
						text-align: center

					&.price, &.count 
						text-align: right


			tbody 

				tr 
					background: $color-background
					border: $border-color 2px solid
				
				td, th
					background: $color-background
					padding: 10px 17px
					border-top: $border-color 2px solid
					border-bottom: $border-color 2px solid
					
					@media screen and (max-width: $content-width-narrow) 
						min-width: 120px 
						width: 120px

					.small
						display: inline-block
						margin-top: 10px
						font-size: 11px
						font-weight: 300
						color: $color-text

					&.variant, &.dimension
						text-align: center
						font-weight: 300

					&.dimension
						font-size: 0.9rem
						min-width: 200px
						width: 200px
						
					&.price, &.count
						text-align: right
						min-width: 150px
						width: 150px

					&:first-child
						border-left: $border-color 2px solid

					&:last-child
						border-right: $border-color 2px solid

						span 
							display: none
					
					label.input
						max-width: 80px
						display: inline-block


				th 
					text-transform: uppercase
					color: $color-primary

					div 
						display: flex 
						align-items: center
						justify-content: space-between
						gap: 10px
						max-width: 300px

						&>span:last-child 
							display: inline-block !important
							width: 70px	!important					


	.staircaseStepConfiguratorLayout-radii-sampler
		.grid--samplers
			display: flex 
			flex-wrap: wrap
			gap: 20px

			.cardBox
				width: 120px

			.card-in 
				padding: 0
				padding-top: 5px
				padding-bottom: 5px
			
			.flex 
				display: flex 
				flex-direction: column 
				gap: 20px
				justify-content: center
				align-items: center

				h3 
					white-space: normal
					height: 3rem
					text-align: center
					margin: 0
					margin-left: 10px 
					margin-right: 10px

				div:first-child 
					width: 70px
					height: 70px

					img 
						object-position: cover
						width: 70px !important
						height: 70px !important
						min-height: unset
						max-height: unset
						min-width: unset 
						max-width: unset

				@media screen and (max-width: 900px)
					grid-template-columns: 1fr 4fr


			@media screen and (max-width: 900px)
				grid-template-columns: 1fr 1fr
			
			@media screen and (max-width: 600px)
				grid-template-columns: 1fr

	.staircaseStepConfiguratorLayout-radii-grid
		display: grid 
		grid-template-columns: 40% 60%
		gap: 30px
		max-width: 100%

		.staircaseStepConfiguratorLayout-radii-image-helper-text
			display: none
			@media screen and (max-width: $content-width-narrow)
				display: block
				font-size: 0.9rem
				color: $color-primary-alternative
				position: relative
				top: -4rem
				font-weight: bold
				margin-bottom: -2rem

		.band
			margin-bottom: 0

		.flex
			display: flex
			gap: 30px
			flex-direction: column
			justify-content: space-between

		.flex-inner
			display: flex
			gap: 10px
			align-items: flex-start
			position: relative

			&>*:nth-child(2)
				position: relative
				top: 65px
				font-size: 1.2rem

			.radioButtons-in
				position: relative 
				top: -4px

		h4 
			margin-bottom: 0.5rem

		svg 
			width: calc(100% - 20px)
			max-height: 500px

			text 
				font-size: 18px
				fill: $color-primary-alternative

				&:not(.available):not(.active)
					fill: $color-text

				&.hidden 
					display: none
				

			g[id]

				path, line 
					stroke: rgba(grey, 0.3)
					stroke-width: 2px
					fill: darken($color-background-contained, 4)

				&.available
					cursor: pointer
					
					path, line 
						stroke: #333
						

					@media screen and (max-width: $content-width-narrow)
						&:not(.active):active 
							path, line
								fill: lighten($color-primary, 5)
								stroke: $color-primary-alternative

						&.active:active 
							path, line
								fill: lighten($color-primary, 5)
								stroke: $color-primary-alternative

					@media screen and (min-width: $content-width-narrow)
						&:not(.active):hover 
							path, line
								fill: lighten($color-primary, 5)
								stroke: $color-primary-alternative

						&.active:hover 
							path, line
								fill: lighten($color-primary, 5)
								stroke: $color-primary-alternative


				&.active

					path, line 
						stroke: rgba($color-primary, 1)
						fill: white

				&.hidden

					path, line 
						display: none

		@media screen and (max-width: $content-width)
			grid-template-columns: 1fr 1fr

			.flex-inner input 
				width: 100px

		@media screen and (max-width: 850px)
			grid-template-columns: 1fr
		
			.flex-inner
				display: block


	.staircaseStepConfiguratorLayout-radii-summary
		margin-top: 40px
		padding-bottom: 20px 
		font-size: 1.1rem 
		border-bottom: dotted 3px $color-primary

		table 
			td:last-child 
				padding-left: 2rem
			tfoot tr:first-child 
					td
						padding-top: 2rem

		@media screen and (max-width: $content-width-narrow)
			font-size: 1rem

			table 
				td:last-child 
					padding-left: 0.5rem
					padding-bottom: 1rem

				td:first-child
					padding-right: 2rem
					padding-bottom: 1rem

				tr:last-child td 
					padding-bottom: 0

	.staircaseStepConfiguratorLayout-final

		table 
			width: 100%	
			border-bottom: dotted 3px $color-primary

			td, th 
				font-size: 1rem

			td:nth-child(4), th:nth-child(4)
				color: $color-primary-alternative

			td:nth-child(4), td:nth-child(5)
				text-align: right

			.text-muted
				color: $color-text-light

			th
				text-align: right

			@media screen and (max-width: $content-width-narrow)
				tr
					display: flex
					flex-wrap: wrap 
					justify-content: flex-end 
					margin-bottom: 1rem

					td:nth-child(2), td:nth-child(3)
						text-align: left
						width: 50%
						padding-top: 5px 
						padding-bottom: 5px

					td:first-child
						width: 100%

					td:nth-child(4), td:nth-child(5), th:nth-child(4), th:nth-child(5)
						padding-top: 10px
						padding-left: 10px
						display: inline-block
						width: 30%
						min-width: 100px

			tfoot tr 
				th, td
					padding-top: 2rem
					padding-bottom: 2rem
					

		.grid--share
			display: flex 
			gap: 20px

			&>*:first-child
				flex: 1

			@media screen and (max-width: $content-width-narrow)
				flex-direction: column



		.staircaseSVG 
			g 
				opacity: 1 !important

		.staircaseConfiguratorLayout--share
			padding-top: 2rem 
			padding-bottom: 2rem
			display: flex 
			flex-wrap: wrap
			gap: 20px
			align-items: flex-end

			.shareLink
					width: 100%

	.staircaseStepConfiguratorLayout-radii-collapsible-trigger
		display: none

	@media screen and (max-width: $content-width-narrow)
		.staircaseStepConfiguratorLayout-radii-collapsible
			transition: max-height 200ms
			max-height: 10000px
			overflow-y: hidden 

			&--collapsed
				max-height: 0
				height: 0

		.staircaseStepConfiguratorLayout-radii-collapsible-trigger
			display: block
			margin-top: 1rem


@media print

	.staircaseStepConfiguratorLayout-radii-collapsible-trigger
		display: none 

	.staircaseStepsConfiguratorLayout-inner > h2
		display: block
		margin: 0

	.staircaseStepsConfiguratorLayout-inner-perex
		padding-bottom: 0

	.staircaseStepConfiguratorLayout-export
		background: $color-background-contained

		h1 
			display: block !important

		.intro, .tabs, .spacer
			display: none

	.collapsible
		page-break-after: always
		break-after: page

		header 
			padding-bottom: 1rem

	.staircaseStepConfiguratorLayout--share, .staircaseConfiguratorLayout--share+h3
		display: none !important

	.contactForm
		display: none

	.staircaseStepConfiguratorLayout-radii

		.staircaseStepConfiguratorLayout-radii-grid
			font-size: 0.9rem
			page-break-inside: avoid 
			break-inside: avoid-page

			p 
				display: none

			svg 
				max-height: 400px

		header+div 
			display: block 

			.productTable
				&+.staircaseStepConfiguratorLayout-radii-summary
					display: none

	.staircaseStepConfiguratorLayout-final 
		page-break-before: always
		page-break-inside: avoid
		padding-top: 50px
		padding-bottom: 50px
		break-before: page
		break-inside: avoid-page

		h2 
			display: none

		.grid--share h3 
			display: none

		.grid--price
			page-break-inside: avoid
			break-inside: avoid-page
			

	.staircaseSVG
		max-height: 300px

	.collapsible
		margin-top: 2rem !important

		&:not(.is-printable)
			display: none

		// &.is-printable .collapsible-content
		// 	page-break-before: avoid !important

		// &.is-printable .collapsible-content-in
		// 	page-break-before: avoid !important

	.productTable
		overflow-y: hidden !important
		height: fit-content !important
		page-break-inside: avoid
		

		table 
			height: fit-content !important
			overflow-y: visible !important

			tbody, thead 
				overflow-y: visible !important

		th, td
			font-size: 9pt !important
			padding: 5px !important
			padding-top: 2px !important
			padding-bottom: 2px !important

			.productVariantPicker-single, .productVariantPicker-selected
				padding: 2px

			&.dimension
				min-width: unset !important

			.productVariant-name, .productVariant-description
				font-size: 9pt !important

			&.picture 
				width: 50px 
				height: 50px

				span 
					margin-top: 0 !important

				p 
					margin: 0

			input 
				padding: 3px

			&:is(thead th)
				border-bottom: none


	.button-in
		min-height: 42px

	.staircaseStepsConfiguratorLayout .staircaseStepConfiguratorLayout-radii-sampler .grid--samplers .cardBox
		h3 
			font-size: 1rem

		width: 90px