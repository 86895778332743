@use '../styles/_variables' as *

.contained
	background-color: $color-background-contained
	padding-top: 20px
	padding-bottom: 20px
	flex-grow: 1

	@media (min-width: 800px)
		padding-top: 30px
		padding-bottom: 50px
