@use '../styles/_variables' as *

.intervalSlider
	width: 80vw
	max-width: 320px

	&-selected
		text-align: center
		font-size: 15px
		padding-top: 5px

		&-value
			font-weight: $font-weight-heavy

	&-track
		border-radius: $border-radius
		height: 13px
		width: 100%
		border: 1px solid #C6C6C6
		background-color: #EAEAEA
		margin: 10px 0 15px

	&-thumb
		border-radius: 2px
		background-color: $color-primary
		width: 10px
		height: 22px
