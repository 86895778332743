@use '../styles/_variables' as *
@use 'sass:math'

.gallery
	display: flex
	flex-direction: column
	z-index: 0
	height: 100%

	&-counter,
	&-thumbnails
		flex-basis: 0
		
	&-stage 
		margin-bottom: -90px
		position: relative
		flex-grow: 1

	&-counter
		padding: 10px 18px
		font-weight: $font-weight-book
		font-size: 13px
		opacity: 0.55
		text-align: right

		position: absolute
		top: 0
		left: 0
		right: 0

	&-arrows
		display: flex
		justify-content: space-between
		padding: 7px 16px

		position: absolute
		top: calc(50% - 0.5em)
		left: 0
		right: 0
		z-index: 20

	&-arrow
		$size: 10px

		border: none
		border-left: 2px solid
		border-bottom: 2px solid
		width: $size
		height: $size
		position: relative
		top: math.div(-$size, 2)
		color: $color-primary-alternative
		padding: 0
		background-color: transparent
		transform-origin: 0 100%
		transform: rotate(45deg)
		outline: none

		&:hover,
		&:focus
			color: $color-text

		&::before
			content: ''
			position: absolute
			top: -8px
			left: -8px
			right: -8px
			bottom: -8px

		& + &
			border-left: none
			border-right: 2px solid
			transform-origin: 100% 100%
			transform: rotate(-45deg)

	&-thumbnails
		display: flex
		flex-wrap: wrap
		align-content: flex-end
		justify-content: center
		gap: 5px
		padding: 5px
		min-height: 85px

	&-thumbnail
		width: 56px
		height: 56px
		position: relative
		background-color: #E8E8E8
		border-radius: 3px
		border: 1px solid #DADADA
		overflow: hidden
		text-align: left
		line-height: 0.8
		z-index: 25


		&.is-current	
			border-color: $color-primary-alternative

		.gallery-thumbnail-model-3d
			background: white
			color: #DEDEDE
			position: relative
			display: block
			height: 85%
			padding: 3px
			line-height: 0.7

			svg 
				fill: currentcolor
				position: absolute 
				z-index: 1
				right: -10px
				top: -10px
				transform: scale(0.5)

			span, strong
				color: $color-primary
				position: relative 
				font-size: 1.2rem
				top: 21px
				z-index: 2
				text-align: left