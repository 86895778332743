@use '../styles/_variables' as *

.productListRow
	$color-separator: #D1D1D1
	$thickness-separator: 2px

	&:hover
		background-color: #F5F5F5

	&:first-child
		td
			border-top: $thickness-separator solid $color-separator

	td
		padding: 15px 17px
		border-bottom: $thickness-separator solid $color-separator

		&:first-child
			border-left: $thickness-separator solid $color-separator

		&:last-child
			border-right: $thickness-separator solid $color-separator

	&:last-child td

		&:first-child
			border-bottom-left-radius: $border-radius

		&:last-child
			border-bottom-right-radius: $border-radius

	&-name
		text-align: left
		font-size: 15px
		font-weight: 800
		text-transform: uppercase
		white-space: nowrap

		&-in
			display: flex
			flex-wrap: wrap

		&-link
			margin: 4px 0
			text-decoration: none
			display: flex
			flex-direction: column
			justify-content: center
			gap: 3px

			&:hover,
			&:focus
				text-decoration: underline

		&-new
			display: block

		&-alternative
			font-weight: 400
			font-size: 12px
			width: 100%

	&-dimensions
		display: flex
		align-items: center
		font-size: 15px
		font-weight: 300
		white-space: nowrap

		&-picture
			margin: 3px 9px 3px 0
			display: inline-block
			--productListRow-height: 40px
			width: calc(var(--productListRow-aspect-ratio) * var(--productListRow-height))

			@media (min-width: 1000px)
				--productListRow-height: 56px

	td#{&}-price
		font-size: 15px
		white-space: nowrap
		text-align: right

	&-quantity
		&-in
			display: flex
			align-items: center
			min-width: 100px

	&-variant
		.productVariant
			padding: 0
			.productVariant-icon
				border-radius: $border-radius
				width: 35px 
				height: 35px
				min-height: unset

	&-action
		white-space: nowrap

		.productListRow-action-mobile
			display: none

		@media print
			.button 
				display: none

			.productListRow-action-mobile
				display: block

		

	&-composition-number
		display: inline-flex 
		flex-direction: column
		height: 30px 
		width: 30px 
		text-align: center 
		color: white 
		background: #5F5F5F 
		border: solid white 2px 
		justify-content: center 
		align-items: center 
		border-radius: 50%
		font-size: 0.9rem 
		vertical-align: middle
		margin-right: 0.5rem 
		