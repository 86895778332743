@use '../styles/_variables' as *

.categoryList
	&-items
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(220px, 1fr))
		gap: 50px 40px

	&-item
		position: relative
		border-radius: $border-radius
		overflow: hidden
		background-color: rgba(#000000, 0.1)

		&::before
			content: ''
			display: block
			padding-top: calc(100% / (276 / 174))

		@media print
			page-break-inside: avoid