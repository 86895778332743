@use '../styles/sass-base/mixins/position'
@use '../styles/_variables' as *

.categoryImage
	$root: &
	$transition-time: 200ms

	+position.absolute(0)
	z-index: 0
	display: flex
	justify-content: center
	align-items: center
	text-align: center
	border-radius: $border-radius
	color: $color-text-inverted
	overflow: hidden
	text-decoration: none
	padding: 20px

	&-in
		display: inline-flex
		align-items: center
		z-index: 5

		#{$root}-title
			font-size: 26px
			z-index: 1
			text-transform: uppercase
			text-shadow: 0 0 5px rgba(#000000, 0.9)
			max-width: 100%
			margin: 0
			padding: 0
			line-height: 1

		&--background
			padding: 22px 20px 15px 15px
			background-color: rgba($color-primary, 0.85)

			#{$root}-title
				text-shadow: none

			.arrow
				filter: none !important

	&:has(#{$root}-in--background)
		padding: 0

	&--align-left-top
		justify-content: flex-start
		align-items: flex-start


	&--align-left-bottom
		align-items: flex-end
		justify-content: flex-start

	&--align-right-top
		justify-content: flex-end
		align-items: flex-start

	&--align-right-bottom
		align-items: flex-end
		justify-content: flex-end


	&-pictureDesktop
		@media screen and (max-width: $content-width-narrow)
			display: none
		
	&-pictureMobile
		@media screen and (min-width: $content-width-narrow)
			display: none

	& .picture
		transition: $transition-time transform
		transform: translateZ(0)

	& .arrow
		transition: $transition-time transform
		transform: translateZ(0) translateY(-4px)
		filter: drop-shadow(0 0 5px rgba(#000000, 0.9))

	&:not(&:has(#{$root}-in--background))::before
		content: ''
		display: inline-block
		width: $arrow-width-and-space

	&::after
		+position.absolute(0)
		content: ''
		background-color: #000000
		opacity: 0
		transition: $transition-time opacity

	&:hover
		&::after
			opacity: 0.3

		.picture
			transform: scale(1.05) translateX(2%)

		.arrow
			transform: translateX(3px) translateY(-4px)


		

