@use '../styles/_variables' as *

.topProduct
	min-height: 100%
	display: flex

	&-title
		text-transform: uppercase
		margin: 0 0 10px
		font-size: 26px
		font-weight: $font-weight-normal
		font-family: $font-head

	&-description
		line-height: 22px
		font-size: 15px
		color: #333333

	&-picture
		float: right
		max-width: 90px
		width: 100%
		margin-left: 10px
		margin-top: 5px
		margin-bottom: 3px

	&-action
		clear: both
