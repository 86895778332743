@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.cardPicture
	display: flex

	&.view-download &
		&-image
			min-width: 52px
			height: 71px

	&.view-news &
		&-image
			position: relative
			overflow: hidden
			min-width: 75px
			height: 75px
			border-radius: $border-radius

	&-content
		display: flex
		justify-content: space-between
		flex-direction: column
		padding-left: 13px

	&-title
		font-size: 15px
		font-family: $font-base
		margin: 0 0 5px

		&-extension
			padding-left: 10px
			color: #AAAAAA

	&-link
		+position.absolute(0)
		z-index: 10

		&-text
			position: relative
			z-index: 2
			font-weight: $font-weight-book
