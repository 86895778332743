@use '../styles/_variables' as *

.barelyVisibleTitle
	text-transform: uppercase
	color: #A9A9A9
	font-family: $font-head
	margin: 0
	text-align: center
	font-weight: $font-weight-normal
	position: relative

	h1, h2, h3, h4, h5, h6
		background: $color-background

	&-left
		text-align: left

	&-right
		text-align: right

	&-justify
		text-align: justify

	&-line 
		&::before 
			content: ""
			position: absolute
			bottom: 0
			left: 0
			width: 100% 
			height: 5px 
			background-color: $color-background-contained
			z-index: -1