@use '../styles/_variables' as *
@use "sass:math"

.filters
	$gap: 16px

	display: flex
	margin: math.div(-$gap, 2)

	&-item,
	&-reset
		padding: math.div($gap, 2)

	&-item
		&:not(.is-useful)
			display: none

	&-reset
		align-self: center
		white-space: nowrap

		&-in
			border: none
			padding: 0
			background-color: transparent
			color: $color-primary

			&:hover,
			&:focus
				text-decoration: underline

	div[data-popper-placement]
		z-index: 20
