@use '../styles/_variables' as *

@keyframes productVariantPicker-pulse
	100%
		border-color: transparent

.productVariantPicker
	$root: &
	$transition-duration: 0.2s

	position: relative
	display: inline-block
	color: $color-text !important

	.productVariant-info, .productVariant-price
		color: $color-text !important

		small 
			color: $color-text-light !important

	&-selected,
	&-none,
	&-single
		padding: 8px
		border: 1px solid transparent

	&-selected
		background-color: transparent
		border-radius: $border-radius
		display: flex
		align-items: center
		justify-content: space-between
		outline: none
		min-width: 140px

		&:hover,
		&:focus,
		#{$root}.is-open &,
		#{$root}.view-forceBorder &,
		#{$root}.is-highlight:not(.is-open) &
			border-color: $border-color
			background-color: $color-background

		&::after
			content: ''
			border-left: 2px solid transparent
			border-right: 2px solid transparent
			border-top: 3px solid
			margin-left: 0.5em
			transform-origin: 50% 35%
			transition-property: transform
			transition-duration: $transition-duration
			color: $color-text !important

			#{$root}.is-open &
				transform: scaleY(-1)

	&-options
		pointer-events: auto
		transition-property: opacity, visibility
		transition-duration: $transition-duration
		opacity: 0
		visibility: hidden

		#{$root}.is-open &
			opacity: 1
			visibility: inherit

		&-in
			margin: 3px
			background-color: $color-background
			border-radius: $border-radius
			border: 1px solid $border-color
			min-width: 240px
			box-shadow: 9px 7px 15px rgba(#000000, 0.1)

	&-option
		padding: 8px 15px
		display: block
		width: 100%
		background-color: transparent
		border: none

		& + &
			border-top: 1px solid $border-color

		&:hover,
		&:focus
			background-color: rgba(#000000, 0.03)

	&::before
		content: ''
		border: 3px solid rgba($color-primary, 0.8)
		border-radius: $border-radius
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		margin: -1px
		pointer-events: none
		opacity: 0
		transition-property: opacity
		transition-duration: $transition-duration * 2

	&.is-highlight:not(.is-open)::before
		opacity: 1
		animation: productVariantPicker-pulse 0.5s alternate infinite ease-out
