@use '../styles/_variables' as *

.regionMap
	position: relative
	padding: 40px 0

	@media screen and (max-width: $content-width-narrow)
		padding: 90px 0 40px 0

	&-in
		position: relative
		max-width: 1000px
		margin: 0 auto

		&::before
			content: ''
			display: block
			padding-top: calc(100% / (575 / 299))

	svg
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%

	path,
	polygon
		transition: fill 0.2s
		cursor: pointer

		&.is-hover
			fill: rgba($color-primary, 0.4)

		&.is-active
			fill: $color-primary

	&-regionDetail
		display: grid
		gap: 10px
		position: absolute
		top: -25px
		right: 0
		max-height: 100%
		overflow: auto
