@use '../styles/_variables' as *

.productList
	width: 100%
	display: grid 
	grid-template-columns: minmax(0, 1fr)
	overflow: auto

	&-note
		padding: 30px 0

	&-table
		width: 100%
		border-collapse: separate
		border-spacing: 0

		th
			font-weight: inherit
			text-align: center

			b
				font-weight: $font-weight-heavy

		td
			text-align: left

			&:last-child
				text-align: center

	&-header
		background-color: #E3E3E3
		color: $color-text
		font-weight: $font-weight-book
		font-size: 12px
		white-space: nowrap

		th
			padding: 14px 17px 10px

			&:first-child
				border-top-left-radius: $border-radius

			&:last-child
				border-top-right-radius: $border-radius

		th#{&}-name,
		th#{&}-variant
			text-align: left

		th#{&}-variant
			padding-left: 26px

		th#{&}-price
			text-align: right

	&-items
		background-color: $color-background
