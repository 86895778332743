@use '../styles/_variables' as *

.textWithImage
	text-align: left

	&-image
		margin-top: 20px

	@media (min-width: 700px)
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 40px

		&-image
			margin-top: 0

		&-text
			//

		&.view-swap &-image
			order: -1
