@use '../styles/_variables' as *

.categoryTag
	$root: &

	border: 1px solid
	border-radius: 11px
	font-size: 11px
	color: $color-text-light
	font-weight: 700
	display: inline-block
	padding: 5px 12px
	margin: 0 0 8px
	line-height: 1
	text-transform: none

	&.view-disableGutters
		margin: 0

	&.view-highlight
		color: $color-highlight
		position: relative
		top: -1px

		& #{$root}-in
			position: relative
			top: 1px
			text-transform: uppercase
