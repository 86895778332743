@use '../styles/_variables' as *

.checkboxList
	&-items
		list-style-type: none
		padding: 0
		margin: 0
		text-align: left
		display: grid
		grid-template-columns: repeat(var(--checkboxList-column-count, 1), auto)
		column-gap: 16px

	&-item
		padding: 4px 0
