@use '../styles/_variables' as *

.references

	.popup.is-open
		position: fixed
		z-index: 1500
		left: 1rem
		bottom: 5rem
		padding: 0
		margin: 0

		.band
			margin: 0
			padding: 0

			&-in:after, &-in:before 
				display: none

	&-detail
		text-align: left

	&-return
		margin-bottom: 10px
		display: inline-block

	&-title
		font-family: $font-head
		font-size: 38px
		font-weight: $font-weight-normal
		margin: 0 0 30px

	&-image
		position: relative
		z-index: 0
		border-radius: $border-radius
		overflow: hidden
		background-color: #D9D9D9

		&::before
			content: ''
			display: block
			padding-top: calc(100% / (16 / 9))

		&-open
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			border: none
			background-color: transparent
			width: 100%
				outline: none

		
	// 	position: absolute
	// 	top: 0
	// 	left: 0
	// 	border: none
	// 	padding: 1em
	// 	background-color: transparent
	// 	color: #FFFFFF
	// 	text-shadow: 0 0 10px #000000
	// 	z-index: 1
	// 	text-decoration: underline
	// 	overflow: hidden

	// 	&:hover,
	// 	&:focus
	// 		text-decoration: none

	&-products
		margin-top: 50px

	&-navigation
		margin-top: 20px
		display: flex
		justify-content: space-between

	
.references-product-list
	position: absolute
	bottom: 3rem

	.productList
		max-width: calc(100vw - 40px)

	@media screen and (max-width: $content-width-narrow)
		max-height: 60vh
		bottom: 2rem
		overflow-y: auto