@use '../styles/_variables' as *




.homepageTilesGrid
	$mascotSize: 180px
	$break: 1000px
	$gap: 2px

	position: relative
	max-width: $content-width-wide + 2 * $page-horizontal-spacing
	margin: 0 auto $gap

	&-in 

		position: relative
		width: 100%
		
		display: grid
		gap: $gap
		grid-template: 'a a' 'b c' 'd e' / 1fr 1fr

		@media (min-width: $break)
			grid-template: 'a b b c' 'a d e e' / 24fr 17fr 14fr 17fr

		&-item
			display: flex
			// overflow: hidden
			position: relative

			&::after
				// Force min height with aspect ratio 1:1
				content: ''
				padding-top: 100%

			&-in
				position: relative
				background-color: #E4E4E4

			&::after,
			&-in
				flex-shrink: 0
				width: 100%

			&:nth-child(1)
				grid-area: a

				&::after
					display: none

			&:nth-child(2)
				grid-area: b

			&:nth-child(3)
				grid-area: c

			&:nth-child(4)
				grid-area: d

			&:nth-child(5)
				grid-area: e

			@media (min-width: $break)
				&:nth-child(2),
				&:nth-child(5)
					&::after
						display: none

			.garden
				@media screen and (max-width: $content-width)
					margin-bottom: $mascotSize / 2 * 1.6666
				
				@media screen and (max-width: $content-width-small)
					margin-bottom: $mascotSize / 2 * 1.3333

	.mascot 
		pointer-events: none
		position: absolute
		bottom: calc( 0.1 * $mascotSize)
		right: calc( -0.25 * $mascotSize)
		width: $mascotSize
		aspect-ratio: 1
		z-index: 10	

		@media screen and (max-width: $content-width-wide)
			right: calc( 0.1 * $mascotSize)
			bottom: calc( -0.25 * $mascotSize)

		@media screen and (max-width: $content-width)
			bottom: calc( -0.1 * $mascotSize)

		@media screen and (max-width: $content-width-narrow)
			bottom: calc( -0.1 * $mascotSize)

		@media print 
			display: none