@use '../styles/_variables' as *

.share
	display: flex
	align-items: center

	@media (min-width: $break-share)
		flex-direction: column

	&-title
		margin: 0 7px 0 0
		font-family: $font-base
		font-weight: $font-weight-heavy
		font-size: 17px
		text-align: center

		@media (min-width: $break-share)
			margin-right: 0

	&-targets
		margin: 0
		padding: 0
		list-style-type: none
		display: flex
		justify-content: center
		align-items: center

		@media (min-width: $break-share)
			flex-direction: column
			margin-top: 5px

	&-target
		display: flex
		margin: 5px

		&-in
			display: inline-flex
			justify-content: center
			align-items: center
			width: 35px
			height: 35px
			color: inherit
			background-color: currentColor
			padding: 0
			border: none
			border-radius: 50%

			&:hover,
			&:focus
				color: $color-primary

		&:not(.is-native) &-in
			background-color: transparent

			&:hover,
			&:focus
				color: $color-primary

	&-icon

		&-native
			color: $color-text-inverted
			position: relative
			left: -1px
			font-size: 19px

		&-facebook,
		&-linkedin,
		&-pinterest
			//
