@use '../styles/_variables' as *

.shoppingListLayout
	$narrow-width: 500px
	$break: 600px
	$breakMax: $break - 1px

	@media (min-width: $break)
		display: grid
		grid-template-columns: 1fr auto

	&-productList
		grid-column: 1 / -1

		.productListRow-name
			white-space: normal

	&-actions
		margin-top: 0.7em
		grid-column: 1

		@media (max-width: $breakMax)
			display: none

		@media print
			display: none

		&-in
			display: inline-grid
			grid-template-columns: auto auto
			gap: 16px

	&-prices
		display: grid
		grid-template-columns: 1fr
		gap: 20px
		text-align: center
		font-size: 26px
		max-width: 80%
		margin-left: auto 
		margin-right: auto


		@media (min-width: 400px)
			font-size: 26px

		@media (min-width: 600px)
			grid-template-columns: 1fr 1fr
			max-width: $narrow-width
			margin: 0 auto

		@media (min-width: $break)
			font-size: 28px

		&-label
			font-size: 12px
			margin-bottom: 4px
			font-weight: $font-weight-heavy
			text-align: left

			@media (min-width: 400px)
				text-align: center


		&-noVat
			color: $color-primary
			div 
				text-align: left

		&-vat
			div 
				text-align: left

			
			//

	&-submit
		margin-top: 24px
		text-align: center
		max-width: $narrow-width
		margin: 24px auto 0

		@media print
			display: none

	&-prices,
	&-submit
		@media (min-width: 600px)
			grid-column: 2
