@use '../styles/_variables' as *

.pageTransitionIndicator
	@keyframes pageTransitionIndicator-progress
		0%
			transform: translateX(-100%)
		50%
			transform: translateX(0%) scaleX(0.5)
		100%
			transform: translateX(100%)

	position: fixed
	top: 0
	left: 0
	right: 0
	z-index: 30
	overflow: hidden
	transition-property: opacity, visibility, transform
	transition-duration: 0.2s
	opacity: 0
	visibility: hidden
	transform: translateY(-100%)
	pointer-events: none

	&.is-loading
		opacity: 1
		visibility: visible
		transform: none

	&::before,
	&::after
		position: relative
		content: ''
		height: 5px
		background-color: $color-primary
		display: block

	&::after
		position: absolute
		top: 0
		left: 0
		right: 0
		background-color: $color-primary-alternative
		animation: pageTransitionIndicator-progress 1s linear infinite
